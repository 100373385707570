import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import ImgCustom from "src/components/img-custom"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { mathRoundNumber } from "src/utils/common"
import { convertCurrency } from "src/utils/convert-currency"
import { DealVoucherType } from "../types"

const DealVoucherModal = (props: DealVoucherType) => {
  const {
    voucherImg,
    reward,
    voucherName,
    minSpend,
    isRoom,
    discountType,
    isDisplay,
    endTime,
    clearCurrentVoucher,
    title,
  } = props
  const { t } = useTranslation()
  const [time, setTime] = useState<number | undefined>(undefined)
  const [isReadyTime, setIsReadyTime] = useState<boolean>(false)

  const getValueReward = useMemo(() => {
    if(discountType === "PERCENTAGE") {
      return `${mathRoundNumber(reward)}%`
    } else {
      return convertCurrency(reward)
    }
  }, [])

  // check valid time of voucher
  const isValidTime = useMemo(() => {
    const result = true
    if(!isDisplay) return false
    if(endTime) {
      const end = new Date(endTime).getTime()
      const now = new Date().getTime()
      if(end - now <= 0) return false
    }
    return result
  }, [endTime])

  // init time
  useMemo(() => {
    if(isRoom && isValidTime) {
      if(endTime) {
        const end = new Date(endTime).getTime()
        const now = new Date().getTime()
        if(end - now >=0) {
          const timeValue = Math.round((end - now)/1000)
          setTime(timeValue)
        }
      }
      setIsReadyTime(true)
    }
  }, [isValidTime, isRoom])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let interval: any = undefined
    if (isRoom && isValidTime && isReadyTime && time) {
      interval = setInterval(() => {
        if (time) setTime(time - 1)
        else {
          clearInterval(interval)
          if (clearCurrentVoucher) clearCurrentVoucher()
        }
      }, 1000)
    }
    return () => {
      if (interval) clearInterval(interval)
    }
  }, [isRoom, time, isValidTime, isReadyTime])

  const getVoucherName = useMemo(() => {
    let result = voucherName
    if(result.length > 15) {
      result = result.slice(0,13) + "..."
    }
    return result
  }, [voucherName])

  if (isRoom && !isValidTime) return null
  return (
    <div className="flex w-full rounded-3 border border-orange-dark-300 bg-white">
      <div className="flex items-center justify-center px-[20px] py-[22px]">
        <div className="h-[48px] w-[48px]">
          <ImgCustom
            alt="voucherImg"
            url={voucherImg}
            className="h-full w-full"
          />
        </div>
      </div>
      <div className="my-3 border-r border-dashed border-orange-dark-300"></div>
      <div className="flex flex-1 gap-1 p-3">
        <div className="flex flex-1 flex-col">
          <span className="text-[14px] font-semibold leading-[20px] text-gray-700 line-clamp-2">
            {title}
          </span>
          <span className="text-[14px] font-semibold leading-[20px] text-gray-700 line-clamp-2">
            {`${capitalizedFirst(t("live-stream.reward", { value: getValueReward }))}`}
          </span>
          <span
            className="target-voucher-name mt-[2px] text-[14px] leading-[20px] text-gray-500 line-clamp-1 cursor-pointer">
            {getVoucherName}
          </span>          
          <span className="mt-[2px] text-[12px] font-medium leading-[16px] text-gray-700 ">
            {capitalizedFirst(
              t("live-stream.min-spend", {
                value: convertCurrency(minSpend || 0),
              })
            )}
          </span>
        </div>
      </div>
    </div>
  )
}

export default DealVoucherModal
