import axios from "axios"

const storageVariableName = "currency-conversation-data"
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertCurrency = (value?: any, showSymbol?: boolean) => {
  if (showSymbol == undefined) showSymbol = true
  // if (!value)
  //   return (0).toLocaleString("en-US", {
  //     style: "currency",
  //     currency: currencyData.currency,
  //   })

  // validateAndFetchCurrency()
  const currencyData = JSON.parse(
    localStorage.getItem("currency-conversation-data") || "{}"
  )
  if (currencyData?.rate) {
    return (value * currencyData.rate).toLocaleString("en-US", {
      style: "currency",
      currency: currencyData.currency,
    })
  } else {
    return (value * 1).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    })
  }
}
export const validateAndFetchCurrency = async (isNotReload?: boolean) => {
  if (!localStorage.getItem(storageVariableName)) {
    localStorage.setItem(
      storageVariableName,
      JSON.stringify({
        currency: "USD",
        rate: 1,
        expiry: new Date().getTime(),
        hash: "",
      })
    )
  }
  const localData = JSON.parse(
    localStorage.getItem(storageVariableName) || "{}"
  )
  const isFetchingCurrency = JSON.parse(
    localStorage.getItem("isFetchingCurrency") || "false"
  )
  if (
    localData.hash !== localData.currency + localData.rate + localData.expiry ||
    localData.expiry < new Date().getTime()
  ) {
    localStorage.setItem("isFetchingCurrency", "true")
    if (!isFetchingCurrency) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/billing/exchange-rate/USD/${localData.currency}`
        )
        if (response.data.success) {
          localData.rate = response.data.data
          localData.expiry = new Date().getTime() + 30 * 60 * 1000 // 30 minutes
          localData.hash =
            localData.currency + localData.rate + localData.expiry
          localStorage.setItem(storageVariableName, JSON.stringify(localData))
          // if (!isNotReload) location.reload()
        }
        localStorage.setItem("isFetchingCurrency", "false")
      } catch (error) {
        console.error(`Error fetching exchange rate: ${isNotReload}`, error)
        localStorage.setItem(
          storageVariableName,
          JSON.stringify({
            currency: "USD",
            rate: 1,
            expiry: new Date().getTime(),
            hash: "",
          })
        )
      }
    }
  } else {
    localStorage.setItem(storageVariableName, JSON.stringify(localData))
  }
}

export const updateCurrency = (currency: string, isNotReload?: boolean) => {
  try {
    const localData = JSON.parse(
      localStorage.getItem(storageVariableName) || "{}"
    )
    const data = {
      ...localData,
      currency: currency,
    }
    localStorage.setItem(storageVariableName, JSON.stringify(data))
  } catch (error) {
    localStorage.setItem(
      storageVariableName,
      JSON.stringify({
        currency: currency,
        rate: 1,
        expiry: new Date().getTime(),
        hash: "",
      })
    )
  }
  validateAndFetchCurrency(isNotReload)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const customValueByRate = (rate: number, value: any ) => {
  const currencyData = JSON.parse(
    localStorage.getItem("currency-conversation-data") || "{}"
  )
  if (currencyData?.currency === "VND") {
    return (value / rate).toLocaleString("en-US", {
      style: "currency",
      currency: currencyData.currency,
    })
  } else {
    return (value * 1).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    })
  }
}