/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react"
import { Dialog } from "primereact/dialog"
import { ShopAdminModalType, ShopAdminType } from "./props"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import EmptyPage from "src/pages/empty-page"
import ShopAdminTable from "./member-table"
import Loading from "./loading"
import "./index.scss"
import { getMemberShop } from "src/services/user-service"

export default function ShopAdminModal(props: ShopAdminModalType) {
  const { t } = useTranslation()
  const { isShow, handleClose, myShopId } = props
  const [shops, setShops] = useState<ShopAdminType[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const FooterContent = (
    <div className="mt-3 flex justify-end text-16 font-semibold leading-24 gap-1">
      <button
        className="rounded-3 border border-gray-300 px-18px py-10px text-gray-700 hover:bg-gray-100"
        onClick={() => handleClose()}
      >
        {capitalizedFirst(t("global.close"))}
      </button>
    </div>
  )
  const getMember = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getMemberShop(myShopId).then(((res: any)=> {
      if(res?.data?.data?.length) {
        const result = [] as ShopAdminType[]
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        res.data.data.forEach((i: any) => {
          if(i?.id && i?.name && i?.email) {
            result.push(i)
          }
        })
        if(result.length) setShops(result)
      }
    }))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((err: any) => console.log(err)).finally(() => setIsLoading(false))
  }
  useEffect(() => {
    getMember()
  }, [])

  if (!isShow) return null
  return (
    <Dialog
      id="memberShop"
      draggable={false}
      header={capitalizedFirst(t("join-shop.member-title"))}
      visible={isShow}
      footer={FooterContent}
      onHide={() => handleClose()}
    >
      <div className="flex min-h-[200] md:min-h-[300px] w-full flex-col items-center md:w-[800px]">
        {isLoading ? <div className="flex items-center justify-center w-full h-full m-auto"><Loading /></div> : null}
        {!shops.length && !isLoading ? <EmptyPage message={capitalizedFirst(t("global.no-data-found"))}/> : null}
        {shops.length && !isLoading ? (
          <div className="w-full flex flex-col gap-2">
            <ShopAdminTable shops={shops} isSpecShop={true} myShopId={myShopId} />
          </div>
        ) : null}
      </div>
    </Dialog>
  )
}
