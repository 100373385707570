import React, { useEffect, useMemo, useRef, useState } from "react"
import HeaderView from "./header-view"
import ShopInformationForm from "./shop-information"
import UserInformationForm from "./user-information"
import { FormikTouched, FormikValues, setNestedObjectValues, useFormik } from "formik"
import * as Yup from "yup"
import { RestaurantTypeControllerApi } from "@soctrip/axios-restaurant-service"
import { configHeader } from "src/config/interceptors"
import { SERVICE, TYPE_PAGE } from "src/constants/common"
import { RestaurantType, InputFormType, DocumentType, TypeEnum } from "../types"
import { ShopControllerApi, ShopFile } from "@soctrip/axios-shop-service"
import { useNavigate } from "react-router-dom"
import VerifiedInformation from "./verified-information"
import { ImageType } from "./upload-image"
import { uploadFiles } from "src/services/file-service"
import { useToast } from "src/hooks/use-toast"
import { useTranslation } from "react-i18next"
import PolicyModal from "src/components/modal/policy-modal"

const InputForm = (props: InputFormType) => {
  const { t } = useTranslation()
  const shopInfoRef = useRef<HTMLDivElement>(null)
  const userInfoRef = useRef<HTMLDivElement>(null)
  const verifyInfoRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const { typePage, updateDateComplete } = props
  const [certificates, setCertificates] = useState<ImageType[]>([])
  const [documents, setDocuments] = useState<DocumentType[]>([])
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isShowPolicyModal, setIsShowPolicyModal] = useState<boolean>(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const showToast = useToast()

  const dataForm = useFormik({
    initialValues: {
      shop_name: "",
      shop_category: [],
      user_name: "",
      user_email: "",
      user_phone: "",
      type: TypeEnum.personal,
      note: "",
      currency: "",
      address: [
        {
          index: 0,
          isDefault: true,
          default_label: "Primary address",
          country: null,
          city: null,
          district: null,
          ward: null,
          address: "",
          subAddress: "",
        },
      ],
    },
    validationSchema: Yup.object({
      shop_name: Yup.string().required(t("global.required") as string),
      shop_category: Yup.array(),
      user_name: Yup.string().required(t("global.required") as string),
      user_email: Yup.string()
        .required(t("global.required") as string)
        .email("Please enter the email"),
      user_phone: Yup.string().required(t("global.required") as string),
      currency: Yup.string().required(t("global.required") as string),
      address: Yup.array()
        .of(
          Yup.object(
            {
              country: Yup.object().shape({}).required(t("global.required") as string),
              city: Yup.object().shape({}).required(t("global.required") as string),
              district: Yup.object().shape({}).required(t("global.required") as string),
              ward: Yup.object().shape({}).required(t("global.required") as string),
              address: Yup.string().required(t("global.required") as string),
            }
          )
        ),
    }),
    onSubmit: () => {
      dataForm.resetForm()
    },
    validateOnChange: true,
    validateOnMount: true,
  })
  const [restaurantTypes, setRestaurantTypes] = useState<RestaurantType[]>([])
  const getRestaurantType = () => {
    new RestaurantTypeControllerApi(configHeader(SERVICE.RESTAURANT))
      .restaurantTypesGet()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data?.data?.length) {
          setRestaurantTypes(res.data.data.data as RestaurantType[])
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((err: any) => console.log(err))
  }
  useEffect(() => {
    if (typePage === TYPE_PAGE.FOOD) getRestaurantType()
  }, [])

  const isValidCertificates = () => {
    if(dataForm.values.type === TypeEnum.business) return true
    let countIdValid = 0
    certificates.forEach(i => {
      if(i.file?.type.includes("image")) countIdValid+=1
    })
    return countIdValid === certificates.length
  }
  
  const handleSubmitForm = async () => {
    setIsSubmit(true)
    const errors = await dataForm.validateForm()
    const keys = Object.keys(errors)
    const shopInfoKeys = ["shop_name", "shop_address", "shop_country", "shop_city", "shop_district", "shop_ward", "currency"]
    const userInfoKeys = ["user_name", "user_email", "user_phone"]
    
    console.log(isValid, isValidCertificates, "!11", dataForm)
    if (isValid && isValidCertificates()) {
      setIsShowPolicyModal(true)
    } else {
      dataForm.setTouched(setNestedObjectValues<FormikTouched<FormikValues>>(errors, true))
      if(keys.find(key => shopInfoKeys.includes(key))) {
        if(shopInfoRef.current) {
          shopInfoRef.current.scrollIntoView({ behavior: "smooth" })
        }
      } else if(keys.find(key => userInfoKeys.includes(key))) {
        if(userInfoRef.current) {
          userInfoRef.current.scrollIntoView({ behavior: "smooth" })
        }
      } else {
        if(verifyInfoRef.current) {
          verifyInfoRef.current.scrollIntoView({ behavior: "smooth" })
        }
      }
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getAddressField = (data: any) => {
    return {
      id: data.id as string,
      name: data.name as string,
      code_name: data.code_name as string,
      code: data.code_name as string,
      postal_code: data.postal_code || "000000",
    }
  }

  const getAddressList = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result = [] as any[]
    dataForm.values.address.forEach(i => {
      const wardData = i.ward ? i.ward : i.district
      const dataAddress = {
        name: i.default_label,
        country: getAddressField(i.country),
        province: getAddressField(i.city),
        district: getAddressField(i.district),
        ward: getAddressField(wardData),
        street: i.address,
        sub_street: i.subAddress,
        is_default: i.isDefault,
      }
      result.push(dataAddress)
    })
    return result
  }
  const getRequestData = () => {
    const requestData = {
      name: dataForm.values.shop_name,
      address_list: getAddressList(),
      user_name: dataForm.values.user_name,
      email: dataForm.values.user_email,
      phone: dataForm.values.user_phone,
      certificate_type: (dataForm.values.type == TypeEnum.personal ? "INDIVIDUAL" : "ENTERPRISE") ,
      other_document: [...documents.filter(item => !!item).map((d) => d.data)] as ShopFile[],
      certificate: [],
      currency: dataForm.values.currency,
      note: dataForm.values.note,
    }
    return requestData
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getAddressShop = (data: any) => {
    let result = ""
    if (data?.street) result = result + data.street
    if (data?.ward?.name && data?.ward?.id !== data?.district?.id)
      result = result + ", " + data.ward.name
    if (data?.district?.name) result = result + ", " + data.district.name
    if (data?.province?.name) result = result + ", " + data.province.name
    if (data?.country?.name) result = result + ", " + data.country.name
    return result
  }

  const createShopPersonal = () => {
    setIsSubmitting(true)
    const requestData = getRequestData()
    uploadFiles(certificates.map((c) => c.file)).then(res => {
      requestData.certificate = res?.data?.data ?? []
      new ShopControllerApi(configHeader(SERVICE.SHOP))
        .shopsInfoPost(requestData)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          if (res?.data?.data) {
            setIsSubmitting(false)
            const dataComplete = {
              shop: {
                name: res.data.data?.name,
                address: getAddressShop(res.data.data),
              },
              user: {
                name: res.data.data?.owner_name,
                email: res.data.data?.email,
                phone: res.data.data?.phone,
              },
            }
            setIsShowPolicyModal(false)
            updateDateComplete(dataComplete)
          }
        })
        .catch((err) => {
          showToast({ detail: err?.response?.data?.error?.message, severity: "error" })
          setIsSubmitting(false)
        })
    })
  }

  const createShopBusiness = () => {
    setIsSubmitting(true)
    const requestData = getRequestData()
    requestData.certificate = []
    new ShopControllerApi(configHeader(SERVICE.SHOP))
      .shopsInfoPost(requestData)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data) {
          setIsSubmitting(false)
          const dataComplete = {
            shop: {
              name: res.data.data?.name,
              address: getAddressShop(res.data.data),
            },
            user: {
              name: res.data.data?.owner_name,
              email: res.data.data?.email,
              phone: res.data.data?.phone,
            },
          }
          setIsShowPolicyModal(false)
          updateDateComplete(dataComplete)
        }
      })
      .catch((err) => {
        showToast({ detail: err?.response?.data?.error?.message, severity: "error" })
        setIsSubmitting(false)
      })
  }

  const createShopProduct = () => {
    if(dataForm.values.type === TypeEnum.personal) createShopPersonal()
    else createShopBusiness()
  }

  const isValid = useMemo(() => {
    const isValidForm = dataForm.isValid && !isSubmitting
    // const isValidFile = dataForm.values.type === TypeEnum.personal ? certificates.length === 2 && certificates[0] && certificates[1] : true 
    // const isValidDocument = dataForm.values.type === TypeEnum.business ? documents.length : true
    return isValidForm
  }, [dataForm])

  const handleSubmitRequest = () => {
    createShopProduct()
  }
  return (
    <div className="max-w-[1200px]">
      <div className="flex flex-col">
        <HeaderView />
        <div ref={shopInfoRef}>
          <ShopInformationForm
            dataForm={dataForm}
            typePage={typePage}
            restaurantType={restaurantTypes}
          />
        </div>
        <div ref={userInfoRef}>
          <UserInformationForm dataForm={dataForm} />
        </div>
        <div ref={verifyInfoRef}>
          <VerifiedInformation dataForm={dataForm}
            isSubmit={isSubmit}
            setCertificates={setCertificates}
            documents={documents}
            setDocuments={setDocuments}
          />
        </div>
        <div className="mt-4 mb-4 flex justify-end">
          <div className="text-16 font-semibold leading-24 flex">
            <button
              onClick={() => navigate("/shopping")}
              className="rounded-3 border-gray-300 bg-white px-[18px] py-[10px] text-gray-700"
            >
              {t("global.cancel")}
            </button>
            <button
              onClick={handleSubmitForm}
              className="flex items-center gap-4px ml-2 rounded-3 px-[18px] py-[10px] text-white border-blue-600 bg-blue-600"
            >
              {t("request-open-page.send-request")}
              {isSubmitting && <i className="pi pi-spin pi-spinner" style={{ fontSize: "1rem" }}></i>}
            </button>
          </div>
        </div>
      </div>
      {
        isShowPolicyModal ? 
          (
            <>
              <PolicyModal isShow={isShowPolicyModal} handleClose={() => setIsShowPolicyModal(false)} handleSubmit={handleSubmitRequest}/>
            </>
          ) : null
      }
    </div>
  )
}

export default InputForm
