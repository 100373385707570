import React, { useEffect, useMemo, useRef, useState } from "react"
import { Menu } from "primereact/menu"
import ImgCustom from "../img-custom"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "src/app/store"
import coinIcon from "src/assets/images/setting-menu-coin.svg"
import shipIcon from "src/assets/images/setting-menu-ship-icon.svg"
import socialIcon from "src/assets/images/setting-social-module.svg"
import shopIcon from "src/assets/images/setting-shop-module.svg"
import foodIcon from "src/assets/images/setting-food-module.svg"
import flightIcon from "src/assets/images/setting-flight-module.svg"
import hotelIcon from "src/assets/images/setting-hotel-module.svg"
import carIcon from "src/assets/images/setting-car-module.svg"
import tourIcon from "src/assets/images/setting-tours-module.svg"
// import myBookingIcon from "src/assets/images/setting-my-booking.svg"
import saveListIcon from "src/assets/images/setting-save-list.svg"
import partnerIcon from "src/assets/images/setting-partner-center.svg"
import megaIcon from "src/assets/images/mega-live-icon.svg"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import i18n from "src/locales"
import LanguageModal from "../modal/language-modal"
import { isIFrame } from "../shareToken/types"
import { resetListDataCartLocal } from "src/features/shopping-cart"
import { signOut } from "src/features/auth"
import { isProdMode } from "src/utils/app-logo"
import { getAcceptMegaLive } from "src/services/user-service"
import shopModuleIcon from "src/assets/images/shop-module.webp"
import carModuleIcon from "src/assets/images/car-module.webp"
import tourModuleIcon from "src/assets/images/tour-module.webp"
import hotelModuleIcon from "src/assets/images/hotel-module.webp"
import socialModuleIcon from "src/assets/images/social-module.webp"
import restaurantModuleIcon from "src/assets/images/restaurant-module.webp"
// import programIcon from "src/assets/images/setting-program.svg"
const moduleData = [
  {
    icon: socialIcon,
    label: "Social",
    url: `${process.env.REACT_APP_HOME_DOMAIN_URL}`,
  },
  {
    icon: shopIcon,
    label: "Shopping",
    url: "shop",
  },
  {
    icon: foodIcon,
    label: "Foods",
    url: `${process.env.REACT_APP_FOOD_DOMAIN_URL}`,
  },
  {
    icon: flightIcon,
    label: "Flights",
    url: `${process.env.REACT_APP_FLIGHT_BETA_DOMAIN_URL}/booking/flight`,
  },
  {
    icon: hotelIcon,
    label: "Hotels",
    url: `${process.env.REACT_APP_HOTEL_BETA_DOMAIN_URL}`,
  },
  {
    icon: carIcon,
    label: "Cars",
    url: `${process.env.REACT_APP_BOOKING_CAR_BETA_DOMAIN_URL}/booking/car`,
  },
  {
    icon: tourIcon,
    label: "Tours",
    url: `${process.env.REACT_APP_TOUR_BETA_DOMAIN_URL}`,
  },
]

const SettingView = () => {
  const authStore = useSelector((state: RootState) => state.auth)
  const userMenuRef = useRef<Menu>(null)
  const centralMenuRef = useRef<Menu>(null)
  const [isShow, setIsShow] = useState<boolean>(false)
  const { t } = useTranslation()
  const [isShowLanguageModal, setIsShowLanguageModal] = useState<boolean>(false)
  const [isShowSignOut, setIsShowSignOut] = useState<boolean>(false)
  const [isGetMegaLive, setIsGetMegaLive] = useState<boolean>(false)
  const [isMegaLive, setIsMegaLive] = useState<boolean>(false)

  const dispatch = useDispatch()
  const pageData = useMemo(() => {
    return [
      {
        icon: saveListIcon,
        label: capitalizedFirst(t("global.saved-list")),
        url: `${process.env.REACT_APP_TOUR_BETA_DOMAIN_URL}/saved-list`,
        key: `saved-${new Date().getTime()}`,
        isAuth: true,
      },
      {
        icon: partnerIcon,
        label: capitalizedFirst(t("header.central")),
        url: process.env.REACT_APP_BUSINESS_CENTRAL_DOMAIN_URL,
        key: `central-${new Date().getTime()}`,
        isAuth: false,
        isCentral: true,
      },
      {
        icon: megaIcon,
        label: capitalizedFirst(t("header.mega")),
        url: process.env.REACT_APP_MEGA_LIVE_URL,
        key: `mega-${new Date().getTime()}`,
        isAuth: true,
        isVerify: true,
        isNewFeature: false,
      },
    ]
  }, [i18n.language])

  const viewData = useMemo(() => {
    return [
      {
        icon: "sctr-icon-file-02",
        label: t("header-setting.my-orders"),
        url: "/shopping/orders",
        isLocal: true,
        key: `orders-${new Date().getTime()}`,
      },
      {
        icon: "sctr-icon-video-recorder",
        label: t("header-setting.livestream"),
        url: "/shopping/livestream",
        isLocal: true,
        key: `livestream-${new Date().getTime()}`,
      },
      {
        icon: "sctr-icon-wallet-02",
        label: t("header-setting.my-wallet"),
        url: process.env.REACT_APP_MY_WALLET_DOMAIN_URL,
        isLocal: false,
        key: `wallet-${new Date().getTime()}`,
      },
    ]
  }, [i18n.language])

  const navigate = useNavigate()
  const avatarImg = useMemo(() => {
    return authStore?.value ? authStore?.value.user?.avatar_thumbnail_url : ""
  }, [authStore])
  const getCoinValue = useMemo(() => {
    return 0
  }, [])
  const goToModule = (moduleUrl: string) => {
    if (moduleUrl === "shop") {
      navigate("/shopping")
    } else window.open(moduleUrl, "_self")
  }
  const goToPage = (pageUrl: string) => {
    window.open(pageUrl, "_self")
  }
  const goToView = (viewUrl: string, isLocal: boolean) => {
    if (isLocal) {
      navigate(viewUrl)
    } else {
      window.open(viewUrl, "_blank")
    }
  }
  const handleSaveLanguage = () => {
    setIsShowSignOut(false)
    const frame = document.getElementById("socialLogin")
    if (isIFrame(frame) && frame.contentWindow) {
      const messageData = {
        type: "disconnect",
        data: {
          token: localStorage.getItem("accessToken"),
          user_id: localStorage.getItem("userId"),
        },
      }
      frame.contentWindow.postMessage(messageData, "*")
    }
    localStorage.clear()
    dispatch(signOut())
    console.log(isShowSignOut, "signOut")
    dispatch(resetListDataCartLocal())
    setTimeout(() => {
      window.open(`${process.env.REACT_APP_HOME_DOMAIN_URL}/sign-up`, "_self")
    }, 200)
  }

  const lngData = useMemo(() => {
    return [
      {
        value: "en",
        label: "English",
      },
      {
        value: "vi",
        label: "Tiếng Việt",
      },
    ]
  }, [])

  const getLangDisplay = useMemo(() => {
    const result = lngData.find((i) => i.value === i18n.language)
    return result ? result.label : "English"
  }, [i18n.language])

  const getViewDisplay = (isAuth: boolean, isVerify?: boolean, isNewFeature?: boolean) => {
    if(isNewFeature && isProdMode()) return "hidden"
    let result = "flex"
    if(isAuth) {
      if(authStore.value) {
        if(isVerify && !isMegaLive) {
          result = "hidden"
        }
      } else {
        result = "hidden"
      }
    }
    return result
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleEnterView = (e:any, isCentral: boolean) => {
    if(isCentral) centralMenuRef?.current?.show(e)
  }
  const items = [
    {
      template: () => {
        return (
          <div className="flex flex-col bg-gray-100">
            <div className="relative flex h-[178px] flex-col rounded-t-3">
              <div className="h-[116px] w-full rounded-t-3 bg-[linear-gradient(99.24deg,#1879FF_12.47%,#0658CC_98.43%)]"></div>
              <div className="w-full flex-1 bg-white"></div>
              <div className="absolute left-[16px] top-[44px] flex w-[calc(100%-32px)] flex-col gap-1 rounded-3 border border-gray-200 bg-white p-1">
                <div className="flex items-center justify-between gap-[12px]">
                  <div className="flex items-center gap-[12px]">
                    <div
                      className="h-[40px] w-[40px] rounded-full border-[2px] border-orange-500"
                      onClick={(e) => userMenuRef?.current?.toggle(e)}
                    >
                      <ImgCustom
                        isAvatar={true}
                        alt="userAvatar"
                        url={avatarImg as string}
                        className="border-whit h-full w-full rounded-full border object-cover object-center"
                      />
                    </div>
                    <div className="flex flex-col">
                      <span className="text-[16px] font-semibold leading-[24px] text-gray-700">
                        {authStore.value?.user?.full_name}
                      </span>
                      <span className="text-[12px] leading-[16px] text-gray-500">
                        {authStore.value?.user?.email || ""}
                      </span>
                    </div>
                  </div>
                  <i className="sctr-icon-qr-code-02 cursor-pointer text-[24px] text-blue-600" />
                </div>

                <div className="flex gap-[12px]">
                  <div className="flex flex-1 items-center justify-between rounded-3 bg-amber-50 py-1 px-[12px]">
                    <div className="flex flex-col">
                      <span className="text-[16px] font-semibold leading-[24px] text-gray-900">
                        {capitalizedFirst(t("header-setting.coin"))}
                      </span>
                      <span className="text-[14px] leading-[20px] text-gray-700">
                        {getCoinValue}
                      </span>
                    </div>
                    <img
                      src={coinIcon}
                      alt="coinIcon"
                      className="h-[28px] w-[28px]"
                    />
                  </div>

                  <div className="flex flex-1 items-center justify-between rounded-3 bg-red-50 py-1 px-[12px]">
                    <span className="text-[16px] font-semibold leading-[24px] text-gray-900">
                      {capitalizedFirst(t("header-setting.ship"))}
                    </span>
                    <img
                      src={shipIcon}
                      alt="coinIcon"
                      className="h-[32px] w-[32px]"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="grid grid-cols-4 gap-1 bg-white px-1">
                {moduleData.map((i) => (
                  <div
                    onClick={() => goToModule(i.url as string)}
                    key={i.label}
                    className="flex cursor-pointer flex-col items-center justify-center gap-2px py-1 hover:bg-blue-50"
                  >
                    <img
                      className="h-[40px] w-[40px]"
                      src={i.icon}
                      alt={i.label}
                    />
                    <span className="text-[12px] font-medium leading-[16px] text-gray-700 ">
                      {i.label}
                    </span>
                  </div>
                ))}
              </div>
              {/* LANGUAGE */}
              <div className="mt-4px flex flex-col bg-white px-1">
                <div
                  onClick={() => setIsShowLanguageModal(true)}
                  className="cursor-pointer hover:bg-blue-50"
                >
                  <div className="flex items-center justify-between py-1">
                    <div className="flex items-center gap-[12px] pl-[12px]">
                      <i className="sctr-icon-globe-01 text-[24px]" />
                      <span className="text-[16px] leading-[24px] ">
                        {capitalizedFirst(t("header.language"))}
                      </span>
                    </div>
                    <div className="flex items-center gap-1">
                      <span>{getLangDisplay}</span>
                      <i className="sctr-icon-chevron-right text-[24px]" />
                    </div>
                  </div>
                </div>
              </div>
              {/* SETTING */}
              <div className="mt-4px flex flex-col bg-white px-1">
                {pageData.map((i) => (
                  <div
                    onMouseEnter={(e) => handleEnterView(e, i?.isCentral || false)}
                    onClick={() => goToPage(i.url as string)}
                    key={i.key}
                    className={`central-module cursor-pointer items-center justify-between gap-4px hover:bg-blue-50 ${getViewDisplay(i.isAuth, i?.isVerify, i?.isNewFeature)}`}
                  >
                    <div className="flex items-center gap-[12px] px-[12px] py-1">
                      <img
                        className="h-[28px] w-[28px]"
                        alt={i.label}
                        src={i.icon}
                      />
                      <span className="text-[14px] font-medium leading-[20px] text-gray-900">
                        {i.label}
                      </span>
                    </div>
                    {
                      i.label === capitalizedFirst(t("header.central")) ? (
                        <>
                          <i className="sctr-icon-chevron-right text-[24px]"/>
                          <Menu model={itemsCentral} popup ref={centralMenuRef} className=" translate-x-[380px] mt-[-45px] w-[200px]"/>
                        </>
                      ) : null
                    }
                  </div>
                ))}
              </div>
              {/* VIEW DATA */}
              <div className="mt-4px flex flex-col bg-white px-1">
                {viewData.map((i) => (
                  <div
                    onClick={(e) => {
                      userMenuRef?.current?.toggle(e)
                      goToView(i.url as string, i.isLocal)
                    }}
                    key={i.key}
                    className={`cursor-pointer items-center justify-between gap-4px hover:bg-blue-50 ${authStore.value ? "flex" : "hidden"}`}
                  >
                    <div className="flex items-center gap-[12px] px-[12px] py-1">
                      <i className={`text-[24px] ${i.icon}`} />
                      <span className="text-[14px] font-medium leading-[20px] text-gray-900">
                        {i.label}
                      </span>
                    </div>
                    <i className="sctr-icon-chevron-right text-[24px]" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      },
    },
  ]
  const dataCentral = [
    {
      label: "Shop",
      link: process.env.REACT_APP_ECOMMERCE_DOMAIN_URL,
      icon: shopModuleIcon,
    },
    {
      label: "Hotel",
      link: `${process.env.REACT_APP_HOTEL_BETA_DOMAIN_URL}/ota/hotel-ota`,
      icon: hotelModuleIcon,
    },
    {
      label: "Car Rental",
      link: `${process.env.REACT_APP_BOOKING_CAR_BETA_DOMAIN_URL}/booking/car/become-agency`,
      icon: carModuleIcon,
    },
    {
      label: "Travel tour",
      link: `${process.env.REACT_APP_TOUR_BETA_DOMAIN_URL}/request-become-agency`,
      icon: tourModuleIcon,
    },
    {
      label: "Social",
      link: `${process.env.REACT_APP_HOME_DOMAIN_URL}`,
      icon: socialModuleIcon,
    },
    {
      label: "Restaurant",
      link: `${process.env.REACT_APP_FOOD_DOMAIN_URL}/food/request-open-restaurant`,
      icon: restaurantModuleIcon,
    },
  ]
  const itemsCentral = [
    {
      template: () => {
        return (
          <div className="flex flex-col bg-white rounded-3 p-1">
            {dataCentral.map((i) => (
              <>
                <a href={i.link} className="flex items-center cursor-pointer hover:bg-gray-300 gap-1">
                  <img src={i.icon} className="w-[24px] h-[24px]"/>
                  <span className="text-gray-700 font-medium text-[16px] leading-24px">{i.label}</span>
                </a>
              </>
            ))}
          </div>
        )
      },
    },
  ]

  const getMegaLiveRole = () => {
    getAcceptMegaLive()
      .then((res) => {
        if (res?.data?.data?.length) setIsMegaLive(true)
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setIsGetMegaLive(true)
      })
  }

  useEffect(() => {
    getMegaLiveRole()
  }, [])

  if (!isGetMegaLive) return null
  return (
    <>
      <div
        className={`flex h-[36px] w-[36px] cursor-pointer items-center justify-center hover:text-blue-600 ${
          !isShow ? "text-gray-500" : "text-blue-600"
        }`}
        onClick={(e) => userMenuRef?.current?.toggle(e)}
      >
        <i className="sctr-icon-dots-grid text-[20px]" />
      </div>
      <Menu
        onShow={() => setIsShow(true)}
        onHide={() => setIsShow(false)}
        model={items}
        popup
        ref={userMenuRef}
        className="mt-1 w-full rounded-3 md:w-[384px]"
      />
      {isShowLanguageModal && (
        <LanguageModal
          isShow={isShowLanguageModal}
          title={capitalizedFirst(t("header.language-data.title"))}
          message={""}
          handleClose={() => setIsShowLanguageModal(false)}
          handleConfirm={handleSaveLanguage}
        />
      )}
    </>
  )
}

export default SettingView
