import { InputText } from "primereact/inputtext"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useDebounce } from "use-debounce"
import { CampaignType } from "../props"
import SaleCampaignManagement from "./SaleCampaignManagement"
import { getCampaignSeller } from "src/services/home-connect-service"

export interface MerchantSaleCampaignType {
  shopId: string
  shopName: string
}
export default function MerchantSaleCampaign(props: MerchantSaleCampaignType) {
  const {shopId, shopName} = props
  const { t } = useTranslation()
  const [searchValue, setSearchValue] = useState<string>("")
  const [isSearching, setIsSearching] = useState(false)
  const [searchDebounce] = useDebounce(searchValue, 800)
  const [campaigns, setCampaigns] = useState<CampaignType[]>([])
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [totalData, setTotalData] = useState<number>(0)
  const [itemPerPage, setItemPerPage] = useState<number>(15)
  const [isChangeData, setIsChangeData] = useState<boolean>(false)

  const getCampaign = () => {
    setCampaigns([])
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getCampaignSeller(shopId, currentPage, itemPerPage, searchValue).then((res: any) => {
      if(res?.data?.data?.data?.length) {
        setTotalData(res.data.data?.totalElement || 0)
        const data = [] as CampaignType[]
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        res.data.data.data.forEach((i:any) => {
          data.push({
            image: i?.thumbnail?.id || "",
            name: i?.name || "",
            id: i?.id || "",
            startDate: i?.start_date || "",
            endDate: i?.end_date || "",
            endOfSale: i?.start_of_sale || "",
          })
        })
        setCampaigns(data)
      }
    })
      .catch(err => console.log(err)).finally(() => {
        if(isChangeData) setIsChangeData(false)
      })
  }
  useEffect(() => {
    setCurrentPage(0)
    setItemPerPage(15)
    setTimeout(() => {
      setIsChangeData(true)
    }, 200)
  }, [searchDebounce])

  useEffect(() => {
    if(isChangeData) getCampaign()
  }, [isChangeData])
  useEffect(() => {
    setIsSearching(false)
    getCampaign()
  }, [])

  return (
    <div className="mt-[24px] w-full rounded-[12px] bg-white">
      <div className="p-[16px]">
        <span className="p-input-icon-left w-full max-w-[320px]">
          {isSearching ? (
            <i className="pi pi-spin pi-spinner" />
          ) : (
            <i className="pi pi-search" />
          )}
          <InputText
            className="flex h-[36px] w-full items-center rounded-3"
            placeholder={capitalizedFirst(
              t("merchant-campaign.search-campaign")
            )}
            value={searchValue}
            onChange={(event) => {
              setSearchValue(event.target.value)
            }}
          />
        </span>
      </div>

      <div>
        <SaleCampaignManagement
          campaigns={campaigns}
          itemPerPage={itemPerPage}
          totalItems={totalData}
          onChangeData={() => setIsChangeData(true)}
          currentPage={currentPage}
          setCurrentPage={(newPage: number) => setCurrentPage(newPage)}
          setItemPerPage={(newItemPerPage:number) => setItemPerPage(newItemPerPage)}
          shopId={shopId}
          shopName={shopName}
        />
      </div>
    </div>
  )
}
