import http from "src/config/interceptors"


export const getCampaignSeller = (shopId: string, pageNum: number, pageSize: number, search: string) => {
  const filters = encodeURIComponent(`name|id@=${search}`)
  const url = `/shop/home-connects/seller/${shopId}/campaigns/home-page?pageNum=${pageNum}&pageSize=${pageSize}${search ? `&filters=${filters}` : ""}`
  return http.get(url)
}

export const getCampaignProducts = (shopId: string, campaignId: string, pageNum: number) => {
  const url = `/stock/home-connect/seller/${shopId}/campaigns/${campaignId}/products/home-page?pageNum=${pageNum}&pageSize=15&sort=-updated_at`
  return http.get(url)
}