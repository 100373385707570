import { InputTextarea } from "primereact/inputtextarea"
import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton"
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react"
import UploadImage, { ImageType } from "./upload-image"
import { DocumentType, TypeEnum } from "../types"
import { generateId } from "src/utils/generate-id"
import { uploadFile } from "src/services/file-service"
import { ProgressSpinner } from "primereact/progressspinner"
import { useTranslation } from "react-i18next"
import { useToast } from "src/hooks/use-toast"
import { capitalizedFirst } from "src/utils/capitalized-first-character"

interface VerifiedInformationProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataForm: any
  setCertificates: Dispatch<SetStateAction<ImageType[]>>
  documents: DocumentType[]
  setDocuments: Dispatch<SetStateAction<DocumentType[]>>
  isSubmit: boolean
}

export default function VerifiedInformation({
  dataForm,
  setCertificates,
  documents,
  setDocuments,
  isSubmit,
}: VerifiedInformationProps) {
  const { t } = useTranslation()
  const showToast = useToast()
  const documentRef = useRef<HTMLInputElement>(null)
  const [isUploadingDocument, setIsUploadingDocument] = useState(false)
  const [idCardFront, setIdCardFront] = useState<ImageType>({
    url: "",
    file: null,
  })
  const [idCardBack, setIdCardBack] = useState<ImageType>({
    url: "",
    file: null,
  })

  const onChangeDocument = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files) {
      onSelectDocument(files)
    }
  }

  const onSelectDocument = (files: FileList) => {
    setIsUploadingDocument(true)
    for (let i = 0; i < files.length; i++) {
      const id = generateId(8)
      const document: DocumentType = {
        id,
        name: files[i].name,
        size: (files[i].size / 1000).toFixed(),
        file: files[i],
        data: null,
      }
      uploadDocument(document, i == files.length - 1)
    }
  }

  const uploadDocument = async (document: DocumentType, isLast: boolean) => {
    try {
      const res = await uploadFile(document.file)
      if (res.data?.data) {
        const newDocument = {
          ...document,
          data: res.data.data,
        }
        setDocuments((prev) => [...prev, newDocument])
      }
      if (res.data.error?.message) {
        showToast({ detail: res.data.error?.message, severity: "error" })
      }

      if (isLast) {
        setIsUploadingDocument(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    const files = event.dataTransfer?.files
    if (files) {
      onSelectDocument(files)
    }
  }

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleDeleteDocument = (document: DocumentType) => {
    const newDocument = [...documents].filter((item) => item.id !== document.id)
    setDocuments(newDocument)
  }

  const handleChangeType = (e: RadioButtonChangeEvent) => {
    dataForm.setFieldValue("type", e.value)
    setDocuments([])
    setIdCardBack({ url: "", file: null })
    setIdCardFront({ url: "", file: null })
    setCertificates([])
  }

  useEffect(() => {
    const certificates: ImageType[] = []
    if (idCardFront.url) certificates.push(idCardFront)
    if (idCardBack.url) certificates.push(idCardBack)

    setCertificates(certificates)
  }, [idCardFront, idCardBack])

  return (
    <div className="rounded mt-3 w-full rounded-3 border-gray-200 bg-white p-3">
      <div className="flex flex-col lg:flex-row">
        <div className="flex-1">
          <div className="flex flex-col gap-4px">
            <span className="leading-28px text-[18px] font-semibold text-gray-900">
              {t("request-open-page.verified-information")}
            </span>
            <span className="text-14 leading-20 text-gray-700">
              {t("request-open-page.verified-information-description")}
            </span>
          </div>
        </div>
        <div className="ml-4 flex w-full flex-col lg:w-[760px]">
          {/* TYPE */}
          <div className="flex flex-col gap-4px">
            <label className="text-14 font-medium">
              {t("request-open-page.type")}{" "}
            </label>
            <div className="flex gap-5">
              <div className="flex items-center">
                <RadioButton
                  inputId="type-personal"
                  name="type"
                  value={TypeEnum.personal}
                  onChange={handleChangeType}
                  checked={dataForm?.values?.type == TypeEnum.personal}
                />
                <label
                  htmlFor="type-personal"
                  className="ml-1 text-14 font-medium"
                >
                  {t("request-open-page.personal")}
                </label>
              </div>
              <div className="flex items-center">
                <RadioButton
                  inputId="type-business"
                  name="type"
                  value={TypeEnum.business}
                  onChange={handleChangeType}
                  checked={dataForm?.values?.type == TypeEnum.business}
                />
                <label
                  htmlFor="type-business"
                  className="ml-1 text-14 font-medium"
                >
                  {t("request-open-page.business")}
                </label>
              </div>
            </div>
          </div>

          {/* ID CARD */}
          {dataForm?.values?.type == TypeEnum.personal ? (
            <>
              <div className="mt-3 flex flex-col gap-4px">
                <label className="mb-1 text-14 font-medium">
                  {t("request-open-page.id-card")}
                </label>
                <div className="flex gap-3">
                  {/* FRONT */}
                  <div className="flex flex-1 flex-col gap-1">
                    <div
                      className={`border  ${
                        isSubmit && (idCardFront.file && !idCardFront.file?.type.includes("image"))
                          ? "rounded-3 border-red-500"
                          : "border-transparent"
                      }`}
                    >
                      <UploadImage
                        image={idCardFront}
                        setImage={setIdCardFront}
                        title={t("request-open-page.front")}
                      />
                    </div>
                    {isSubmit &&
                    (idCardFront.file && !idCardFront.file?.type.includes("image")) ? (
                        <span className="text-14 text-red-500">
                          {capitalizedFirst(
                            t(
                              idCardFront.url &&
                                !idCardFront.file?.type.includes("image")
                                ? "global.only-image-type"
                                : "global.required"
                            )
                          )}
                        </span>
                      ) : null}
                  </div>

                  {/* BACK */}
                  <div className="flex flex-1 flex-col gap-1">
                    <div
                      className={`border ${
                        isSubmit &&
                        (idCardBack.file &&
                          !idCardBack.file?.type.includes("image"))
                          ? "rounded-3 border-red-500"
                          : "border-transparent"
                      }`}
                    >
                      <UploadImage
                        image={idCardBack}
                        setImage={setIdCardBack}
                        title={t("request-open-page.back")}
                      />
                    </div>
                    {isSubmit &&
                    (idCardBack.file &&
                      !idCardBack.file?.type.includes("image")) ? (
                        <span className="text-14 text-red-500">
                          {capitalizedFirst(
                            t(
                              idCardBack.url &&
                                !idCardBack.file?.type.includes("image")
                                ? "global.only-image-type"
                                : "global.required"
                            )
                          )}
                        </span>
                      ) : null}
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {/* OTHER DOCUMENT */}
          <div className="mt-3 flex flex-col gap-1">
            <input
              className="hidden"
              accept="application/pdf, application/vnd.ms-excel"
              ref={documentRef}
              type="file"
              multiple
              onClick={() => {
                if (documentRef.current) {
                  documentRef.current.value = ""
                }
              }}
              onChange={(e) => onChangeDocument(e)}
            />
            <label className="text-14 font-medium">
              {t(
                "request-open-page." +
                  (dataForm.values.type == TypeEnum.personal
                    ? "other-documents"
                    : "business-documents")
              )}
            </label>
            <div className="relative flex flex-col gap-1">
              {isUploadingDocument && (
                <div className="absolute top-0 right-0 bottom-0 left-0 z-10 flex items-center justify-center rounded-3 bg-gray-200/50">
                  <ProgressSpinner
                    style={{ width: "50px", height: "50px" }}
                    fill="var(--surface-ground)"
                    animationDuration=".5s"
                  />
                </div>
              )}
              <div
                onDrop={(e) => onDrop(e)}
                onDragOver={(e) => onDragOver(e)}
                onDragLeave={(e) => onDragLeave(e)}
                className="flex h-[96px] flex-col items-center justify-center rounded-4 border bg-white"
              >
                <div className="mb-2 flex h-32px w-32px items-center justify-center rounded-circle bg-gray-100">
                  <i className="sctr-icon-upload-cloud-01"></i>
                </div>
                <div className="mb-4px">
                  <span
                    className="mr-4px cursor-pointer text-14 font-medium text-blue-600"
                    onClick={() => documentRef.current?.click()}
                  >
                    {t("request-open-page.click-to-upload")}
                  </span>
                  <span className="text-14">
                    {t("request-open-page.or-drag-drop")}
                  </span>
                </div>
              </div>

              {/* Display information */}
              {documents.map((document) => (
                <div
                  key={document.id}
                  className="flex items-start gap-2 rounded-3 border p-3"
                >
                  <div className="flex h-32px w-32px items-center justify-center rounded-full bg-gray-100">
                    <i className="sctr-icon-file-05"></i>
                  </div>

                  <div className="flex-1 text-14 font-medium">
                    <div className="text-gray-900">{document.name}</div>
                    <div className="text-gray-500">{document.size} KB</div>
                    <div className="mt-4px flex items-center gap-4px text-emerald-600">
                      <i className="sctr-icon-check-circle"></i>
                      <div className="text-14">
                        {t("request-open-page.upload-success")}
                      </div>
                    </div>
                  </div>

                  <div
                    className="cursor-pointer"
                    onClick={() => handleDeleteDocument(document)}
                  >
                    <i className="sctr-icon-x-close"></i>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* NOTE */}
          <div className="mt-3 flex flex-col gap-4px">
            <label className="mb-1 text-14 font-medium">
              {t("request-open-page.note")}
            </label>
            <InputTextarea
              value={dataForm.values.note}
              autoResize
              rows={5}
              cols={30}
              className="border-gray-200 text-14"
              placeholder={t("request-open-page.enter-note") as string}
              onChange={(e) => dataForm.setFieldValue("note", e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
