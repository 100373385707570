import React, { useState } from "react"
import { Dialog } from "primereact/dialog"
import { JoinShopModalType } from "./props"
import { capitalizedFirst } from "../../../utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import "./index.scss"
import { useToast } from "src/hooks/use-toast"
import { InputText } from "primereact/inputtext"
import ShopIcon from "src/assets/images/shop-icon.png"
import ImgCustom from "src/components/img-custom"
import { joinShop } from "src/services/user-service"

export default function JoinShopModal(props: JoinShopModalType) {
  const { isShow, handleClose, handleConfirm, shopInfo } = props
  const { t } = useTranslation()
  const [isSubmit, setIsSubmit] = useState<boolean>(false)
  const [code, setCode] = useState<string>("")
  const [error, setError] = useState<string>("")
  const showToast = useToast()

  const handleJoin = () => {
    if(!code) setError(t("join-shop.required-code") || "")
    else {
      if(isSubmit) return
      setIsSubmit(true)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      joinShop(shopInfo?.shopId || "", code || "").then((res: any) => {
        if(res?.data?.data) {
          showToast({
            detail: capitalizedFirst(
              t("join-shop.joinSuccess")
            ),
            severity: "success",
          })
          handleConfirm()
        }
      }).catch(() => setError(t("join-shop.joinFail") || "")).finally(() => setIsSubmit(false))
    }
  }
  const footerContent = (
    <div
      className="mt-[24px] flex items-center justify-end gap-3 text-[16px] font-semibold"
      id="dialogFooter"
    >
      <button
        disabled={isSubmit}
        onClick={() => handleClose()}
        className={`rounded-3 border border-gray-300 px-[16px] py-[10px] text-gray-500 ${isSubmit ? "opacity-50" : ""}`}
      >
        {capitalizedFirst(t("global.cancel"))}
      </button>
      <button
        disabled={isSubmit}
        onClick={handleJoin}
        className={`rounded-3 gap-4px flex items-center border border-blue-600 bg-blue-600 px-[16px] py-[10px] text-white ${isSubmit ? "animate-pulse duration-75" : ""}`}
        autoFocus
      >
        {isSubmit ? <i className="pi pi-spin pi-spinner text-16 font-bold text-white"></i> : null}
        <span>{capitalizedFirst(t("global.confirm"))}</span>        
      </button>
    </div>
  )

  if(!isShow) return null
  return (
    <Dialog
      id="JoinShopModal"
      header={t("join-shop.title")}
      visible={isShow}
      style={{ width: "480px" }}
      onHide={() => handleClose()}
      footer={footerContent}
      draggable={false}
      closable={false}
    >
      <div className="flex flex-col gap-[12px] px-[24px]">
        <div className="flex items-center gap-[16px] bg-blue-50 px-[16px] py-[12px] rounded-3">
          {
            shopInfo?.shopAvatar ? (
              <ImgCustom
                url={shopInfo.shopAvatar}
                alt="ShopIcon"
                className="h-[44px] w-[44px] object-contain"
              />
            ) : <img
              src={ShopIcon}
              alt="ShopIcon"
              className="h-[44px] w-[44px] object-contain"
            />
          }
          <span className="text-[16px] leading-[24px] font-semibold text-gray-900">{shopInfo?.shopName}</span> 
        </div>
        <div className="mt-[16px] text-[14px] leading-[20px] text-gray-900">{t("join-shop.info")}</div>
        <div className="mt-[12px] flex flex-col gap-[4px]">
          <InputText
            className={`flex h-[44px] w-full items-center rounded-3 ${error ? "border-red-600" : ""}`}
            placeholder={t("join-shop.enter-code") || ""}
            value={code}
            onChange={(event) => {
              setCode(event.target.value)
              if(error) setError("")
            }}
          />
          {/* error message */}
          {
            error ? <span className="text-[12px] leading-[16px] text-red-600">{error}</span> : null
          }          
        </div>
      </div>
    </Dialog>
  )
}
