import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { ImgCustomType } from "src/constants/constants/image-custom-type"
import MerchantHeaderCustom from "src/components/shopping-homepage-view/merchant-header-custom"
import CategoryView from "src/components/category-view"
import { handleGetMerchantData } from "src/services/merchant-service"
import {
  CategoryTabProperties,
  SectionProperties,
  ShopCategoriesProperties,
  ShopProperties,
} from "./props"
import { InputText } from "primereact/inputtext"
import MerchantProfile from "src/components/merchant-profile"
import { translateCapitalize } from "src/utils/translate-with-capitalize"
import EmptyPage from "../empty-page"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { ALL_PRODUCTS_CODE, SHOP_ROLE } from "src/constants/common"
import AllProducts from "src/components/all-products"
import MerchantSaleCampaign from "./merchant-sale-campaign"

export default function MerchantPage() {
  const navigate = useNavigate()
  const params = useParams()
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [categorySelected, setCategorySelected] = useState<string>("")
  const [background, setBackground] = useState<string>("")
  const [avatar, setAvatar] = useState<string>("")
  const [dataShop, setDataShop] = useState<ShopProperties>()
  const [shopCategoryTab, setShopCategoryTab] =
    useState<CategoryTabProperties[]>()
  const [categoryData, setCategoryData] = useState<ShopCategoriesProperties>()
  const [failed, setFailed] = useState<boolean>(false)

  const SearchInput = () => {
    const [searchValue, setSearchValue] = useState<string>("")
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleKeydown = (event: any) => {
      if (event.key === "Enter") {
        if (searchValue) {
          navigate(
            `/shopping/merchant-page/${dataShop?.id}/search?keyword=` +
              searchValue
          )
        } else {
          navigate(
            `/shopping/merchant-page/${dataShop?.id}/search` + searchValue
          )
        }
      }
    }
    return (
      <div>
        <span className="p-input-icon-left w-full">
          <i className="pi pi-search" />
          <InputText
            className="w-full rounded-3 md:w-[unset] md:min-w-[300px] md:max-w-[400px]"
            onKeyDown={handleKeydown}
            placeholder={translateCapitalize("global.search")}
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
          />
        </span>
      </div>
    )
  }

  // category data content
  useEffect(() => {
    const categoryTabData = dataShop?.shop_categories?.find(
      (data: ShopCategoriesProperties) => data?.id === categorySelected
    )
    setCategoryData(categoryTabData)
  }, [categorySelected])

  const fetchData = () => {
    setLoading(true)
    handleGetMerchantData(params.id)
      .then((response) => {
        if (response?.data?.data) {
          if (!response?.data?.data?.is_market_public) {
            navigate("/not-found")
            return
          }
        }
        setDataShop(response?.data?.data)
        setLoading(false)
        setFailed(false)
      })
      .catch((e) => {
        setLoading(false)
        setFailed(true)
        if (e?.response?.status === 404) navigate("/not-found")
      })
  }

  // detect shopOwner

  // check user isJoinShop

  // get shop data
  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (dataShop) {
      const categoryTab: CategoryTabProperties[] = []
      dataShop?.shop_categories?.forEach(
        (element: ShopCategoriesProperties) => {
          categoryTab.push({ id: element?.id, name: element?.name })
        }
      )
      
      if(dataShop.role === SHOP_ROLE.SPECIAL_SELLER) categoryTab.push({ id: "sale", name: "Sale Compaign" })
      setBackground(dataShop?.background?.id)
      setAvatar(dataShop?.avatar?.id)
      setShopCategoryTab(categoryTab)
      if (categoryTab?.length) {
        setCategorySelected(categoryTab[0]?.id)
      } else {
        setCategorySelected(ALL_PRODUCTS_CODE.CODE)
      }
    }
  }, [dataShop])

  if (failed) {
    return (
      <div className="flex h-[500px] w-full items-center justify-center">
        <EmptyPage />
      </div>
    )
  }

  return (
    <div className="mx-auto w-full max-w-[1200px] px-2 py-3 xl:px-3">
      {loading ? (
        <div className="flex h-full min-h-[500px] w-full items-center justify-center gap-2">
          <div className="flex items-center justify-center text-16 font-semibold text-gray-700">
            {capitalizedFirst(t("global.loading"))}
          </div>
          <i
            className="pi pi-spin pi-spinner text-blue-light-500"
            style={{ fontSize: "2rem" }}
          ></i>
        </div>
      ) : (
        <div className="flex h-full w-full flex-col">
          {/* header */}
          <div className="w-full">
            <div className="rounded-3 border border-gray-100">
              {/* background and info */}
              <div className="relative">
                {/* background image */}
                <MerchantHeaderCustom
                  typeImg={ImgCustomType.image}
                  imgUrl={background}
                />

                <div className="absolute bottom-[55%] w-full md:left-4 md:bottom-[30%] md:right-4 md:w-[90%] lg:bottom-6 lg:left-[70%] lg:w-1/4 xl:bottom-6 xl:right-5 xl:w-1/4">
                  <div className="relative w-full">
                    <i
                      className="pi pi-search absolute left-3 top-[14px] text-gray-500"
                      style={{ fontSize: "1rem" }}
                    />
                    <SearchInput />
                  </div>
                </div>
                <div className="absolute top-[48%] z-[1] w-full rounded-4 sm:left-4 sm:top-[83%] sm:max-w-[450px] md:top-[83%] md:max-w-[650px] md:px-2px lg:top-[83%] xl:top-[83%]">
                  {/* shop info */}
                  <MerchantProfile
                    id={dataShop?.id as string}
                    avatar={avatar}
                    description={dataShop?.slogan}
                    followers={dataShop?.followers}
                    rating={dataShop?.rating}
                    name={dataShop?.name}
                    productNumber={dataShop?.total_products}
                    background={dataShop?.background?.id as string}
                    badge={
                      dataShop?.badges?.length ? dataShop.badges[0] : undefined
                    }
                    isShowJoinView={true}
                    role={dataShop?.role}
                  />
                </div>
              </div>

              {/* category navigation bar */}
              <div className=" mx-auto rounded-b-3 bg-white xl:grid">
                <div className="col-span-6 flex sm:px-1 sm:pt-[55px] md:px-4 md:pt-[55px] lg:items-end lg:px-4 xl:items-end xl:px-4">
                  <div className="flex w-full items-center gap-1">
                    <div className="mr-1">
                      <p
                        className={`ml-[-16px] w-fit cursor-pointer truncate py-1 text-16 font-semibold ${
                          categorySelected === ALL_PRODUCTS_CODE.CODE
                            ? "w-fit border-b-2 border-blue-700 text-blue-700"
                            : "text-gray-500"
                        }`}
                        onClick={() =>
                          setCategorySelected(ALL_PRODUCTS_CODE.CODE)
                        }
                      >
                        {capitalizedFirst(t("global.all-product"))}
                      </p>
                    </div>
                    {shopCategoryTab?.map(
                      (cate: CategoryTabProperties, index: number) => (
                        <div key={index}>
                          <p
                            className={`mr-3 w-fit cursor-pointer truncate py-1 text-16 font-semibold ${
                              categorySelected === cate.id
                                ? "w-fit border-b-2 border-blue-700 text-blue-700"
                                : "text-gray-500"
                            }`}
                            onClick={() => setCategorySelected(cate.id)}
                          >
                            {cate.name}
                          </p>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* content */}
          {categorySelected === ALL_PRODUCTS_CODE.CODE ? (
            <div className="w-full">
              <AllProducts />
            </div>
          ) : categorySelected === "sale" ? (
            <MerchantSaleCampaign shopId={dataShop?.id || ""} shopName={dataShop?.name || ""}/>
          ) : (
            <div className="mt-2 h-full w-full pt-1">
              {categoryData?.shop_sections?.length !== 0 && (
                <div className="flex w-full flex-col ">
                  {categoryData?.shop_sections?.map(
                    (section: SectionProperties) => (
                      <div key={section.id} className="flex w-full">
                        <CategoryView
                          code={section?.shop_decoration_collection?.code}
                          dataContent={section?.contents}
                          fullData={section}
                          shopId={dataShop?.id}
                        />
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
