import React from "react"
import { Column } from "primereact/column"
import { DataTable, DataTableStateEvent } from "primereact/datatable"
import { CampaignTableType, CampaignType } from "../props"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import ImgCustom from "src/components/img-custom"
import "./index.scss"
import { convertViToEn } from "src/utils/common"

export default function SaleCampaignManagement(props: CampaignTableType) {
  const {
    campaigns,
    itemPerPage,
    loading,
    totalItems,
    setCurrentPage,
    setItemPerPage,
    onChangeData,
    shopId,
    shopName,
    currentPage,
  } = props
  const { t } = useTranslation()
  const goToDetailCampaign = (id: string) => {
    return `/shopping/campaign/${convertViToEn(shopName)}/${shopId}/${id}`
  }

  const nameTemplate = (rowData: CampaignType) => {
    return (
      <div className="flex items-center gap-1">
        <div className="h-[32px] w-[32px] overflow-hidden rounded-[4px]">
          <ImgCustom url={rowData.image} alt={rowData.name} />
        </div>
        <a
          className="text-[14px] font-medium text-blue-600"
          href={goToDetailCampaign(rowData.id)}
        >
          <div
            id="product_title"
            className="flex cursor-pointer text-[13px] font-medium leading-18 hover:text-blue-light-500 hover:underline sm:text-14"
          >
            {rowData.name}
          </div>
        </a>
      </div>
    )
  }
  const getTimeFormat = (date: string) => {
    const value = new Date(date)
    const month = value.getMonth() + 1 // +1 because it returns the month as a zero-based index
    return `${value.getDate()}/${month}/${value.getFullYear()}, ${value.getHours() > 9 ? value.getHours() : `0${value.getHours()}` }:${value.getMinutes() > 9 ? value.getMinutes() : `0${value.getMinutes()}`}`
  }
  const idTemplate = (rowData: CampaignType) => {
    return <span>{rowData.id}</span>
  }

  const periodTemplate = (rowData: CampaignType) => {
    return <span>{`${getTimeFormat(rowData.startDate)} - ${getTimeFormat(rowData.endDate)}`}</span>
  }

  const endSaleTemplate = (rowData: CampaignType) => {
    return <span>{`${getTimeFormat(rowData.endOfSale)}`}</span>
  }

  const tableFields = [
    {
      field: "name",
      label: capitalizedFirst(t("merchant-campaign.name")),
      customStyle: {},
      template: nameTemplate,
    },
    {
      field: "id",
      label: "ID",
      template: idTemplate,
    },
    {
      field: "period",
      label: capitalizedFirst(t("merchant-campaign.period")),
      customStyle: {
        width: "330px",
      },
      template: periodTemplate,
    },
    {
      field: "endOfSale",
      label: capitalizedFirst(t("merchant-campaign.end-of-sale")),
      template: endSaleTemplate,
    },
  ]

  const onDataPageChange = (event: DataTableStateEvent) => {
    if(event.rows !== itemPerPage) {
      setItemPerPage(event.rows)
      setCurrentPage(0)
      setTimeout(() => {
        onChangeData()
      }, 200)
      return
    }
    setCurrentPage(event.page || 0)
    setTimeout(() => {
      onChangeData()
    }, 200)
  }
  return (
    <div className="flex flex-col">
      <DataTable
        id="campaign-table"
        value={campaigns}
        dataKey="id"
        paginator
        lazy
        first={currentPage}
        rows={itemPerPage}
        totalRecords={totalItems}
        rowsPerPageOptions={[1, 5, 10, 15, 25, 50]}
        onPage={(e) => onDataPageChange(e)}
        loading={loading}
        selectionPageOnly={true}
        paginatorTemplate="RowsPerPageDropdown PrevPageLink PageLinks NextPageLink"
        className="campaign-table"
      >
        {tableFields.map((item, index) => (
          <Column
            key={index}
            field={item.field}
            header={item.label}
            bodyStyle={item.customStyle}
            body={item.template}
          />
        ))}
      </DataTable>
    </div>
  )
}
