/* eslint-disable indent */
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ORDER_STATE } from "src/constants/common"
import { useToast } from "src/hooks/use-toast"
// import { handleActionState } from "src/services/purchase-history-service"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { translateCapitalize } from "src/utils/translate-with-capitalize"
import { OrderControllerApi } from "@soctrip/axios-shop-service"
import { configHeader } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import * as orderService from "@soctrip/axios-order-service"
import { useNavigate } from "react-router-dom"
import DialogCustom from "../dialog-custom/dialog-custom"
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown"
import { StatusControllerApi } from "@soctrip/axios-order-service"
import { Dialog } from "primereact/dialog"
import i18n from "src/locales"

export default function OrderDetailsFooter(props: {
  id: string
  code: string
  can_cancel?: boolean
  can_receive?: boolean
  can_refund?: boolean
  can_repay?: boolean
  handleCancelOrder: () => void
}) {
  const showToast = useToast()
  const { t } = useTranslation()
  const [isShowConfirmModal, setIsShowConfirmModal] = useState<boolean>(false)
  const [isShowMessageModal, setIsShowMessageModal] = useState<boolean>(false)
  const [isUpdateOrderStatus, setIsUpdateOrderStatus] = useState<boolean>(false)
  const navigate = useNavigate()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [reasons, setReason] = useState<any>()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectReasons, setSelectReason] = useState<any>()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTranslate = (text: any) => {
    return t(text)
  }
  // handle cancel order
  const handleButtonAction = async (id: string, code: string) => {
    if (isUpdateOrderStatus) return
    setIsUpdateOrderStatus(true)
    await new OrderControllerApi(configHeader(SERVICE.SHOP))
      .ordersIdStatusPut(id, code)
      .then(() => {
        if (code == ORDER_STATE.CANCELLED_BY_YOU) {
          setIsShowMessageModal(true)
        } else {
          props.handleCancelOrder()
        }

      })
      .catch(() => showToast({ detail: "Change status failed", severity: "error" }))
      .finally(() => {
        setIsUpdateOrderStatus(false)
        if (code === ORDER_STATE.CANCELLED_BY_YOU) setIsShowConfirmModal(false)
      })
  }

  const getCancelReason = async () => {
    await new StatusControllerApi(configHeader(SERVICE.ORDER))
      .statusReasonsGet("CANCEL", i18n.language)
      .then((res) => {
        setReason(res?.data?.data)
      })
  }

  useEffect(() => {
    getCancelReason()
  }, [])

  const handleConfirmCancel = () => {
    handleButtonAction(props?.id, ORDER_STATE.CANCELLED_BY_YOU)
  }

  const handleCancelOrder = () => {
    setIsShowConfirmModal(true)
  }
  const navigateRequestRefundPage = () => {
    navigate(`/shopping/refund-request/${props.id}`)
  }
  // handle re-pay
  const handleRePay = (id: string) => {
    new orderService.OrderControllerApi(configHeader(SERVICE.ORDER))
      .ordersIdRePayPut(id || "")
      .then((res) => {
        if (res?.data?.data) {
          const paymentUrl = `${process.env.REACT_APP_PAYMENT_DOMAIN_URL}?paymentToken=${res?.data?.data?.token}`
          window.open(paymentUrl, "_self")
        } else {
          showToast({
            detail: "Repay order failed. Please try again!",
            severity: "error",
          })
        }
      })
      .catch(() =>
        showToast({
          detail: "Repay order failed. Please try again!",
          severity: "error",
        })
      )
  }

  const handleCloseMessageModal = () => {
    setIsShowMessageModal(false)
    props.handleCancelOrder()
  }

  const footerContent = (
    <div className="text-gray-900">
      <button className="text-gray-900 border border-gray-300 px-4 py-2 rounded-3 text-sm font-semibold" onClick={handleCloseMessageModal} autoFocus>{capitalizedFirst(handleTranslate("global.close"))}</button>
    </div>
  )

  useEffect(() => {
    if (isShowConfirmModal) getCancelReason()
  }, [isShowConfirmModal, i18n.language])

  return (
    <div className="flex w-full items-center justify-end gap-2 md:px-3">
      {/* <div className="flex cursor-pointer items-center gap-1 rounded-3 border border-blue-600 bg-blue-600 px-6px py-6px text-14 font-semibold text-white md:px-10px md:py-1">
        <span className="pi pi-comment"></span>
        <p>{capitalizedFirst(handleTranslate("purchase-history.button.chat-to-shop"))} </p>
      </div> */}
      {/* cancel order */}
      {props?.can_cancel && (
        <button
          onClick={handleCancelOrder}
          type="button"
          className="truncate rounded-3 border border-gray-300 px-2 py-6px text-14 font-semibold text-gray-700 hover:bg-gray-100 md:px-3 md:py-10px"
        >
          {translateCapitalize("purchase-history.button.cancel-order")}
        </button>
      )}
      {/* receive order */}
      {props?.can_receive && (
        <button
          disabled={isUpdateOrderStatus}
          onClick={() => handleButtonAction(props?.id, ORDER_STATE.COMPLETED)}
          type="button"
          className={`truncate rounded-3 border border-blue-600 bg-blue-600 px-3 py-10px text-14 font-semibold text-white hover:bg-blue-700
            ${isUpdateOrderStatus ? "animate-pulse duration-75" : ""}`}
        >
          {capitalizedFirst(handleTranslate("purchase-history.button.received-order"))}
        </button>
      )}

      {/* pay */}
      {props?.can_repay && (
        <button
          onClick={() => handleRePay(props?.id)}
          type="button"
          className="truncate rounded-3 border border-blue-600 bg-blue-600 px-3 py-10px text-14 font-semibold text-white hover:bg-blue-500"
        >
          {capitalizedFirst(handleTranslate("purchase-history.button.repay"))}
        </button>
      )}
      {/* refund */}
      {props?.can_receive && (
        <button
          onClick={navigateRequestRefundPage}
          type="button"
          className="truncate rounded-3 border border-gray-300 px-3 py-10px text-14 font-semibold text-gray-700 hover:bg-gray-100"
        >
          {translateCapitalize("purchase-history.button.request-refund")}
        </button>
      )}

      <DialogCustom
        header= {translateCapitalize("purchase-history.cancel-order")}
        labelButton="Submit"
        position="center"
        visibleShowDialog={isShowConfirmModal}
        setVisibleShowDialog={() => setIsShowConfirmModal(false)}
        showIconClose={true}
        classLabel="w-[35vw]"
      >
        <div className="flex h-full w-full flex-col gap-4 px-4 pb-3">
          <div className="flex w-full flex-col gap-3">
            <span className="text-sm font-medium text-gray-700">
              {translateCapitalize("purchase-history.are-you-sure-you-want-to-cancel-this-order")}
            </span>
            <div className="flex flex-col gap-1">
              <span className="text-sm font-medium text-gray-900">
                {translateCapitalize("purchase-history.reason")}
                <span className="text-red-500">*</span>
              </span>
              <Dropdown
                value={selectReasons}
                onChange={(e: DropdownChangeEvent) => setSelectReason(e.value)}
                options={reasons}
                optionLabel="name"
                placeholder={translateCapitalize("purchase-history.select-a-reason")}
                className="md:w-14rem flex h-[44px] w-full items-center"
              />
            </div>
          </div>

          <div className="flex w-full items-center justify-end gap-3">
            <button
              onClick={() => setIsShowConfirmModal(false)}
              className="w-[100px] rounded-3 border border-gray-300 bg-gray-50 px-4 py-2 text-16 font-semibold text-gray-700"
            >
              {translateCapitalize("purchase-history.cancel")}
            </button>

            <button
              disabled={!selectReasons || isUpdateOrderStatus}
              onClick={handleConfirmCancel}
              className={`w-[100px] rounded-3 bg-blue-500 px-4 py-2 text-16 font-semibold text-white ${isUpdateOrderStatus ? "animate-pulse duration-75" : ""}`}
            >
              {translateCapitalize("purchase-history.submit")}
            </button>
          </div>
        </div>
      </DialogCustom>
      <Dialog draggable={false} header={translateCapitalize("purchase-history.cancel-successfully")} visible={isShowMessageModal} onHide={handleCloseMessageModal} footer={footerContent}>
        <div className="flex max-w-sm max-h-36 flex-col px-4 pb-3">
          <div className="flex w-full flex-col text-14 font-normal text-gray-700">
            <span>{translateCapitalize("purchase-history.cancel-success-message-1")}</span>
            <span>{translateCapitalize("purchase-history.cancel-success-message-2")}</span>
          </div>
        </div>
      </Dialog>
    </div>
  )
}
