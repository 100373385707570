import { Dialog } from "primereact/dialog"
import React, { useEffect, useState } from "react"
import { InviteMemberModalType } from "./props"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { InputText } from "primereact/inputtext"
import { useFormik } from "formik"
import * as Yup from "yup"
import { HomeConnectControllerApi } from "@soctrip/axios-shop-service"
import { configHeader } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import { useToast } from "src/hooks/use-toast"

export default function InviteMember(props: InviteMemberModalType) {
  const { isShow, handleClose, shopId } = props
  const [messageError, setMessageError] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { t } = useTranslation()
  const showToast = useToast()

  const dataForm = useFormik({
    initialValues: {
      email: undefined,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(capitalizedFirst(t("global.required-message")))
        .email(capitalizedFirst(t("invite-member.email-format-error"))),
    }),
    onSubmit: () => {
      dataForm.resetForm()
    },
    validateOnChange: true,
    validateOnMount: true,
  })

  const isInValidField = () => {
    return !!(dataForm?.touched.email && dataForm?.errors.email)
  }

  const handleCloseModal = () => {
    dataForm.resetForm()
    dataForm.validateForm()
    handleClose && handleClose()
  }

  const handleSummitForm = () => {
    if (!dataForm.isValid) {
      dataForm.setTouched({
        email: true,
      })
      return
    }
    setIsLoading(true)
    if (dataForm.values.email) {
      new HomeConnectControllerApi(configHeader(SERVICE.SHOP))
        .homeConnectsSellerObjectIdInviteCustomersPost(
          shopId,
          dataForm.values.email
        )
        .then(() => {
          showToast({
            detail: t("invite-member.invite-user-success"),
            severity: "success",
          })
          handleClose()
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          const ERROR_MSG_MAPPING: { [key: string]: string } = {
            "This user is already a customer of this seller": t(
              "invite-member.error-user-already-customer"
            ),
            "This email is not existed in Soctrip": t(
              "invite-member.error-user-not-found"
            ),
            "You don't have permission to invite others": t(
              "invite-member.error-not-have-permission"
            ),
          }

          showToast({
            detail:
              ERROR_MSG_MAPPING[error?.response?.data?.error?.message] ||
              t("invite-member.invite-user-failed"),
            severity: "error",
          })
          return
        })
        .finally(() => setIsLoading(false))
    }
  }

  const renderHeader = () => {
    return (
      <div className="flex items-center justify-between text-[24px] font-semibold leading-28 text-gray-700">
        <span>{capitalizedFirst(t("modal.invite-member"))}</span>
      </div>
    )
  }

  const footerContent = () => {
    return (
      <div className="mt-3 flex w-full items-center justify-end gap-[8px]">
        <button
          className="text-black-500 flex items-center justify-center rounded-3 border border-gray-400 py-1 px-3 font-medium transition hover:border-gray-600"
          onClick={() => handleCloseModal()}
        >
          {capitalizedFirst(t("global.cancel"))}
        </button>
        <button
          disabled={isLoading}
          onClick={handleSummitForm}
          type="submit"
          className={`w-full rounded-3 bg-blue-600 px-3 py-1 text-[16px] font-semibold leading-[24px] text-white hover:bg-blue-500 
            ${isLoading ? "animate-pulse duration-75" : ""} `}
        >
          {capitalizedFirst(t("global.invite"))}
        </button>
      </div>
    )
  }

  useEffect(() => {
    if (messageError) setMessageError("")
  }, [dataForm.values])

  if (!isShow) return null
  return (
    <Dialog
      id="shopInviteMemberModal"
      visible={isShow}
      className="w-[480px] max-w-[90%]"
      onHide={() => handleCloseModal()}
      draggable={false}
      header={renderHeader}
      footer={footerContent}
    >
      <div className="mt-2 flex flex-col px-[24px] pb-[24px]">
        <span className="text-14 font-medium leading-20 text-gray-700">
          {capitalizedFirst(t("global.email"))}
          <span className="text-red-500">*</span>
        </span>
        <InputText
          className={`mt-6px rounded-3 border border-gray-300 py-10px px-14px text-16 font-normal leading-24 text-gray-900  ${
            isInValidField() ? "p-invalid" : ""
          } `}
          value={dataForm?.values.email}
          placeholder={capitalizedFirst(t("global.email"))}
          onChange={(e) => {
            if (!dataForm?.touched?.email)
              dataForm.setTouched({
                ...dataForm.touched,
                ...{ email: true },
              })
            dataForm.setFieldValue("email", e?.target?.value)
          }}
        />
        {(dataForm.errors.email && dataForm.touched.email) || messageError ? (
          <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
            {messageError ? messageError : dataForm.errors.email}
          </span>
        ) : null}
      </div>
    </Dialog>
  )
}
