/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import { DataTable } from "primereact/datatable"
import { ShopAdminTableType, ShopAdminType } from "./props"
import { Column } from "primereact/column"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"

export default function MemberTable(props: ShopAdminTableType) {
  const { shops } = props
  const { t } = useTranslation()
  const NameTemplate = (rowData: ShopAdminType) => {
    return (
      <span className="inline-block font-14 max-w-[280px] font-medium leading-20 text-gray-700 whitespace-normal">
        {rowData.name}
      </span>
    )
  }
  const EmailTemplate = (rowData: ShopAdminType) => {
    return (
      <span className="inline-block font-14 max-w-[300px] font-medium leading-20 text-gray-700 break-words">
        {rowData.email}
      </span>
    )
  }

  const tableFields = [
    {
      field: "name",
      label: capitalizedFirst(t("join-shop.member-name")),
      customStyle: {},
      template: NameTemplate,
    },
    {
      field: "email",
      label: capitalizedFirst(t("join-shop.member-email")),
      customStyle: {
        width: "150px",
      },
      template: EmailTemplate,
    },
  ]
  if (!shops.length) return null
  return (
    <div className="w-full" id="memberTable">
      <DataTable value={shops} dataKey="id">
        {tableFields.map((item, index) => (
          <Column
            key={index}
            field={item.field}
            header={item.label}
            bodyStyle={item.customStyle}
            body={item.template}
          />
        ))}
      </DataTable>
    </div>
  )
}
