import React, { useEffect, useState } from "react"
import { CustomCardProps } from "../card-custom/card-custom/custom-card-props"
import CustomCard from "../card-custom/card-custom/custom-card"
import { AllProductsTabProps, ProductViewProps } from "./type"
import { CardCustomType } from "src/constants/constants/card-custom-type"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { FLASH_DEAL_STATUS } from "src/constants/common"

export default function ProductViews(props: ProductViewProps) {
  const [viewMoreLoading, setViewMoreLoading] = useState<boolean>(false)
  const [newData, setNewData] = useState<AllProductsTabProps>()
  const [limit, setLimit] = useState<number>(10)
  const { t } = useTranslation()

  const handleViewMore = () => {
    setViewMoreLoading(true)
    setLimit(limit + 10)
    setViewMoreLoading(false)
  }

  const handleViewLess = () => {
    setViewMoreLoading(true)
    setLimit(10)
    setViewMoreLoading(false)
  }

  
  const detectStructView = (index: number) => {
    if (!newData) return
    const startIndex = Math.floor(index/5) * 5
    const endIndex = startIndex + 5
    const dataDetect = newData?.products.slice(startIndex, endIndex)
    let isSold = false
    let isFlashDeal = false
    let isVoucherShop = false
    dataDetect.forEach(i => {
      if((i?.sold || 0) > 0) isSold = true
      if(i?.flash_deal_status === FLASH_DEAL_STATUS.ONGOGING || i?.flash_deal_status === FLASH_DEAL_STATUS.UPCOMING ) isFlashDeal = true
      if(i?.vouchers?.length) isVoucherShop = true
    })
    const data = {
      isFlashView: isFlashDeal,
      isSoldView: isSold,
      isShopVoucher: isVoucherShop,
    }
    return data
  }

  useEffect(() => {
    setNewData(props?.data)
  }, [props?.data])

  return (
    <div className="flex flex-col gap-1">
      <div className="grid grid-cols-2 gap-3 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5">
        {newData?.products
          ?.slice(0, limit)
          ?.map((pro: CustomCardProps, index: number) => (
            <div key={index}>
              <CustomCard
                key={pro.id}
                id={pro.id}
                typeCard={CardCustomType.product}
                imgUrl={pro.avatar?.id}
                name={pro.name}
                point={pro.point}
                price={pro.price}
                price_after_tax={pro.price_after_tax}
                original_price={pro.original_price}
                original_price_after_tax={pro.original_price_after_tax}
                sum_review={pro.sum_review}
                quantity={pro.quantity}
                voucherOfProducts={[pro.voucher as string]}
                productId={pro.id}
                shop_name={pro.shop?.name}
                sub_label={pro.sub_label}
                shop={pro.shop}
                is_bookmark={pro.is_bookmark}
                handleReload={props?.handleReloadData}
                bloom_filter={props?.bloom_filter}
                sold={pro?.sold}
                is_flash_deal={pro?.is_flash_deal}
                flash_deal_status={pro?.flash_deal_status}
                flash_deals={pro?.flash_deals}
                flash_deals_time={pro?.flash_deals_time}
                shop_id={pro?.shop_id}
                avatar={pro.avatar}
                structView={detectStructView(index)}                          
              />
            </div>
          ))}
      </div>
      {viewMoreLoading ? (
        <i className="pi pi-spin pi-spinner" style={{ fontSize: "1.5rem" }}></i>
      ) : (
        <>
          {newData?.products?.length && newData?.products?.length > limit ? (
            <div className="cursor-pointer text-14 font-semibold text-blue-700">
              <span onClick={handleViewMore}>
                {capitalizedFirst(t("global.view-more"))}
              </span>
            </div>
          ) : null}
          {newData?.products?.length &&
          limit > 10 &&
          newData?.products?.length &&
          limit > newData?.products?.length ? (
              <div
                onClick={handleViewLess}
                className="cursor-pointer text-14 font-semibold text-blue-700"
              >
                {capitalizedFirst(t("global.view-less"))}
              </div>
            ) : null}
        </>
      )}
    </div>
  )
}
