import { StockProductControllerApi } from "@soctrip/axios-stock-service"
import { InputText } from "primereact/inputtext"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useSearchParams } from "react-router-dom"
import GalleriaHomePage from "src/components/galleria-home-page"
import ImgCustom from "src/components/img-custom"
import { BannerType } from "src/components/shopping-homepage-view/shopping-homepage-header/type"
// import ShoppingHomepageFooter from "src/components/shopping-homepage-view/shopping-homepage-footer"
import ShoppingSearchContent from "src/components/shopping-homepage-view/shopping-search-content"
import { configHeader } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import { useClickOutside } from "src/hooks/use-click-outside"
import { getClientBanners } from "src/services/banners-service"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { handleCheckTime } from "src/utils/common"
import { getKeywords } from "src/utils/get-keyword-suggestion"
import { useDebounce } from "use-debounce"

export default function ShoppingSearchProduct() {
  const clickRef = useRef<HTMLDivElement>(null)
  const location = useLocation()
  const [isSuggestionModal, setIsSuggestionModal] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [suggestions, setSuggestions] = useState<string[]>([])
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1)
  const [banner, setBanner] = useState<string>("")
  const { t } = useTranslation()
  const [searchValue, setSearchValue] = useState<string>(
    searchParams.get("keyword") || ""
  )

  const [keyword, setKeyword] = useState(searchParams.get("keyword") || "")
  const [debounceKeyword] = useDebounce(keyword, 500)

  const handleSearch = (suggestion?: string) => {
    if (suggestion) {
      setKeyword(suggestion)
      searchParams.set("keyword", suggestion)
    } else {
      if (keyword) {
        searchParams.set("keyword", keyword)
      } else {
        searchParams.delete("keyword")
      }
    }
    setSearchValue(suggestion || keyword)
    setSearchParams(searchParams)
    setIsSuggestionModal(false)
  }

  const handleKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (isSearching) return
    if (event.key === "ArrowDown") {
      const index =
        selectedSuggestionIndex == suggestions.length - 1
          ? 0
          : selectedSuggestionIndex + 1
      setSelectedSuggestionIndex(index)
    } else if (event.key === "ArrowUp") {
      const index =
        selectedSuggestionIndex == 0
          ? suggestions.length - 1
          : selectedSuggestionIndex - 1
      setSelectedSuggestionIndex(index)
    } else if (event.key === "Enter") {
      if (selectedSuggestionIndex !== -1) {
        handleSearch(suggestions[selectedSuggestionIndex])
      } else {
        handleSearch()
      }
    }
  }

  const fetchSuggestions = async (keyword: string) => {
    setIsSearching(true)
    try {
      const res = await new StockProductControllerApi(
        configHeader(SERVICE.STOCK)
      ).productsPreflightSearchGet(0, 12, keyword)
      const data = res?.data?.data?.data
      if (data) {
        const keywords = getKeywords(data, keyword)
        setSuggestions(keywords)
      }
      setIsSearching(false)
    } catch (error) {
      setIsSearching(false)
    }
  }

  useEffect(() => {
    handleGetBanner()
    if (debounceKeyword) {
      setSelectedSuggestionIndex(-1)
      fetchSuggestions(debounceKeyword.trim())
    } else {
      setSuggestions([])
    }
  }, [debounceKeyword])

  const onClickOutside = () => {
    setIsSuggestionModal(false)
  }

  const highlightMatchedText = (text: string, keyword: string) => {
    const startIndex = text
      .toLocaleLowerCase()
      .indexOf(keyword.toLocaleLowerCase())
    if (startIndex === -1) {
      return text
    }

    const endIndex = startIndex + keyword.length
    const prefix = text.slice(0, startIndex)
    const match = text.slice(startIndex, endIndex)
    const suffix = text.slice(endIndex)

    return (
      <>
        {prefix}
        <strong className="font-semibold text-black">{match}</strong>
        {suffix}
      </>
    )
  }

  const handleGetBanner = async () => {
    try {
      const response = await getClientBanners("SHOP_HERO_BANNER")
      const data: BannerType[] = response?.data?.data || []
      const validBanners: BannerType[] = []

      data.forEach((item) => {
        if (
          handleCheckTime(item?.start_time, item?.end_time) &&
          item?.medias?.length &&
          item?.published
        ) {
          validBanners.push(item)
        }
      })
      if (validBanners.length > 0) {
        setBanner(validBanners[0]?.medias?.[0]?.id)
      }
    } catch (error) {
      console.log(error)
    }
  }

  
  const handleClickSearch = () => {
    if (selectedSuggestionIndex !== -1) {
      handleSearch(suggestions[selectedSuggestionIndex])
    } else {
      handleSearch()
    }
  }
  const getNamePromotion = useMemo(() => {
    let result = ""
    if(location.state?.category?.catalog?.reference) result = location.state?.category?.catalog?.reference
    return result
  }, [location])
  console.log(location.state, "111111", getNamePromotion)
  useClickOutside(clickRef, onClickOutside)

  return (
    <div>
      <div className="relative mx-auto max-w-[1200px] px-3">
        <div className="relative flex h-[320px] items-center">
          {/* BANNER */}
          <div className="absolute ml-[calc((32px)/2*-1)] h-full w-[100vw] xl:ml-[calc((100vw-1200px+32px)/2*-1)]">
            <ImgCustom
              url={banner}
              alt="banner"
              isBanner={true}
              className="h-full w-[full] object-cover"
            />
          </div>
          {/* INPUT */}
          <div 
            ref={clickRef} 
            className="absolute top-[50%] left-1/2 z-[2] flex w-[350px] -translate-x-1/2 -translate-y-1/2 transform sm:w-[450px] md:w-[550px] lg:w-[680px]"
          >
            <div className=" w-full max-w-[680px] mx-[auto] text-center">
              {getNamePromotion ? (
                <span className="text-[20px] font-bold leading-[24px] text-white md:text-[30px] md:leading-[38px] mb-[8px] md:mb-[16px]">
                  {getNamePromotion}
                </span>
              ) : null}
              <span className="p-input-icon-left w-full">
                <i className="pi pi-search" />
                <InputText
                  onClick={() => setIsSuggestionModal(true)}
                  className="h-[44px] w-full rounded-3 pr-[95px]"
                  placeholder={capitalizedFirst(t("global.search"))}
                  value={keyword}
                  onChange={(e) => {
                    setKeyword(e.target.value)
                    setIsSuggestionModal(true)
                  }}
                  onKeyDown={handleKeydown}
                />
                <button
                  type="button"
                  onClick={handleClickSearch}
                  className="absolute right-4px top-1/2 -translate-y-1/2 transform rounded-3 bg-blue-500 py-6px px-2 text-14 font-medium text-white">{capitalizedFirst(t("global.search"))}</button>
              </span>
            </div>
            <div className="absolute top-[56px] left-0 right-0 z-[1] rounded-3 bg-white shadow">
              {suggestions.length > 0 && isSuggestionModal && (
                <ul className="py-3">
                  {suggestions.map((suggestion, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => handleSearch(suggestion)}
                        className={`max-w-full cursor-pointer truncate px-3 py-[4px] text-14 text-gray-700 hover:bg-gray-100 ${
                          selectedSuggestionIndex === index ? "bg-gray-100" : ""
                        }`}
                      >
                        {highlightMatchedText(suggestion, keyword)}
                      </li>
                    )
                  })}
                </ul>
              )}
            </div>
          </div>
        </div>
        <div className="my-2 gap-2 flex flex-col">
          {/* Galleria home page */}
          <GalleriaHomePage/>
          <ShoppingSearchContent searchValue={searchValue} />
        </div>
      </div>
    </div>
  )
}
