import {
  Address,
  AddressAddressTypeEnum,
  AddressControllerApi,
} from "@soctrip/axios-order-service"
import { useFormik } from "formik"
import { Checkbox } from "primereact/checkbox"
import { Dialog } from "primereact/dialog"
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input/max"
import { configHeader } from "src/config/interceptors"
import { useToast } from "src/hooks/use-toast"
import { addressService } from "src/services"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import * as Yup from "yup"
import { ADDRESS_TYPE, SERVICE } from "../../../constants/common"
import PhoneInputNumber from "../../phone-input-number"
import {
  DropdownCountryProps,
  DropdownDistrictProps,
  DropdownProvinceProps,
  DropdownWardProps,
  typeDropdown,
} from "../create-new-address-modal/props"
import { AddressEditType, EditNewAddressModalProps } from "./props"

export default function EditNewAddressModal(props: EditNewAddressModalProps) {
  const { isShow, handleClose, handleConfirm, addressId, isSelected } = props
  const { t } = useTranslation()
  const toast = useToast()
  const [phoneNumber, setPhoneNumber] = useState<string>("")
  const [isDefault, setIsDefault] = useState<boolean>(true)
  const [isNotSupport, setIsNotSupport] = useState<boolean>(false)
  const [addressType, setAddressType] = useState<AddressAddressTypeEnum>(
    AddressAddressTypeEnum.HOME
  )
  const [country, setCountry] = useState<string>("")
  const [city, setCity] = useState<string>("")
  const [district, setDistrict] = useState<string>("")
  const [ward, setWard] = useState<string>("")
  const [dropdownCountryData, setDropdownCountryData] = useState<
    { label: string; value: string }[]
  >([])
  const [dropdownCityData, setDropdownCityData] = useState<
    { label: string; value: string }[]
  >([])
  const [dropdownDistrictsData, setDropdownDistrictsData] = useState<
    { label: string; value: string }[]
  >([])
  const [dropdownWardData, setDropdownWardData] = useState<
    { label: string; value: string }[]
  >([])

  const headerCustom = (
    <div className="flex flex-col">
      <div className="flex items-center justify-between text-18 font-semibold leading-28 text-gray-700">
        <span>{capitalizedFirst(t("modal.editAddress"))}</span>
      </div>
      <span className="mt-4px text-14 font-normal leading-20 text-gray-500">
        {capitalizedFirst(t("modal.addressDescription"))}
      </span>
    </div>
  )

  const dataForm = useFormik({
    initialValues: {
      user_name: "",
      user_phone: "",
      user_address: "",
      user_country: null,
      user_city: null,
      user_district: null,
      user_ward: null,
    },
    validationSchema: Yup.object({
      user_name: Yup.string()
        .required(capitalizedFirst(t("address-modal.field-required")))
        .max(100, capitalizedFirst(t("address-modal.most-30-characters")))
        .matches(
          /^[a-zA-Z0-9À-Ỹà-ỹẠ-Ỹạ-ỹĂ-Ỷă-ỷÂ-Ếâ-ếẤ-Ứấ-ứÊ-Ểê-ểẾ-Ứế-ứÔ-Ộô-ộỐ-Ựố-ựƠ-Ởơ-ởĐđ\s]*$/,
          capitalizedFirst(t("address-modal.invalid-data"))
        )
        .min(3, capitalizedFirst(t("address-modal.enter-full-name"))),
      user_phone: Yup.string()
        .required(capitalizedFirst(t("address-modal.field-required")))
        .test("isValidPhoneNumber", capitalizedFirst(t("address-modal.invalid-phone-number")), (value) =>
          isValidPhoneNumber(value)
        ),

      user_address: Yup.string()
        .required(capitalizedFirst(t("address-modal.field-required")))
        .max(200, capitalizedFirst(t("address-modal.more-than-number-characters"))),
      user_country: Yup.string().required(capitalizedFirst(t("address-modal.field-required"))),
      user_city: Yup.string().required(capitalizedFirst(t("address-modal.field-required"))),
      user_district: Yup.string().required(capitalizedFirst(t("address-modal.field-required"))),
      user_ward: Yup.string().required(capitalizedFirst(t("address-modal.field-required"))),
    }),
    onSubmit: () => {
      if (dataForm.isValid) {
        handleSumitForm()
        dataForm.resetForm()
      }
    },
    validateOnChange: true,
    validateOnMount: true,
  })

  const handlePhoneNumber = (value: string) => {
    setPhoneNumber(value)
  }

  useEffect(() => {
    async function fetchData() {
      await addressService.getAddressById(addressId).then((res) => {
        if (res.data.data) {
          addressService.getCountriesData().then((resCountry) => {
            if (resCountry.data.data && resCountry.data.data.length) {
              const cData = resCountry.data.data.map(
                (item: DropdownCountryProps) => {
                  return {
                    value: item.country_id,
                    label: item.country_name,
                    code: item.code_name,
                  }
                }
              )
              const cSelected = cData.find(
                (i: { label: string }) => i.label === res.data.data.country
              )
              assignInitData(res.data.data)
              setDropdownCountryData(cData)
              setCountry(cSelected.value)
              dataForm?.setFieldValue("user_country", cSelected?.value)
            }
          })
        }
      })
    }
    if (isShow && addressId) fetchData()
  }, [addressId, isShow])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const assignInitData = (data: AddressEditType) => {
    dataForm?.setFieldValue("user_name", data.user_full_name)
    dataForm?.setFieldValue("user_phone", data.user_phone_number)
    dataForm?.setFieldValue("user_address", data.address)
    dataForm?.setFieldValue("user_country", data.country)
    setIsDefault(data.is_default)
    setAddressType(data.address_type)
    setDistrict(data.district)
    setWard(data.ward)
    setCity(data.city)
  }

  const getCityList = (countryId: string) => {
    if (country) {
      const result = [] as { label: string; value: string; code: string }[]
      addressService
        .getProvincesData(countryId)
        .then((resCity) => {
          if (resCity.data?.data && resCity.data?.data.length) {
            resCity.data?.data.forEach((el: DropdownProvinceProps) => {
              result.push({
                value: el.province_id,
                label: el.province_name,
                code: el?.code_name,
              })
            })
          }
          setDropdownCityData(result)
          const cityInit =
            result.find(
              (i: { label: string; value: string }) => i.label === city
            )?.value || null
          if (cityInit) {
            dataForm.setFieldValue("user_city", cityInit)
          }
        })
        .catch((err) => console.log(err))
    }
  }

  const getDistrictList = (cityId: string) => {
    const result = [] as { label: string; value: string; code: string }[]
    addressService
      .getDistrictsData(cityId)
      .then((resDistrict) => {
        if (resDistrict?.data?.data && resDistrict?.data?.data?.length) {
          resDistrict?.data?.data.forEach((el: DropdownDistrictProps) => {
            result.push({
              value: el?.district_id,
              label: el?.district_name,
              code: el?.code_name,
            })
          })
        }
        const districtInit =
          result.find(
            (i: { label: string; value: string }) => i.label === district
          )?.value || null
        if (districtInit) {
          dataForm.setFieldValue("user_district", districtInit)
        }
      })
      .catch((err) => console.log(err))
    setDropdownDistrictsData(result)
  }

  const getWardList = (districtId: string) => {
    const result = [] as { label: string; value: string; code: string }[]
    setIsNotSupport(false)
    addressService
      .getWardsData(districtId)
      .then((resWarn) => {
        if (resWarn.data.data && resWarn.data.data.length) {
          resWarn.data?.data.forEach((el: DropdownWardProps) => {
            result.push({
              value: el.ward_id,
              label: el.ward_name,
              code: el?.code_name,
            })
          })
        } else {
          setIsNotSupport(true)
          toast({
            detail: capitalizedFirst(t("address-modal.address-does-not-support")),
            severity: "warn",
          })
        }
        const wardInit =
          result.find((i: { label: string; value: string }) => i.label === ward)
            ?.value || null
        if (wardInit) {
          dataForm?.setFieldValue("user_ward", wardInit)
        }
        setDropdownWardData(result)
      })
      .catch(() => {
        setIsNotSupport(true)
        toast({
          detail: capitalizedFirst(t("address-modal.address-does-not-support")),
          severity: "warn",
        })
      })
  }

  const getProperty = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dropdownsData: any,
    property: string,
    value: string | null
  ) => {
    const countryItem = dropdownsData.find(
      (i: typeDropdown) => i.value === value
    )
    return countryItem ? countryItem[property] : ""
  }
  const handleSumitForm = () => {
    if (!dataForm.isValid) return
    const data: Address = {
      user_full_name: dataForm?.values?.user_name,
      user_phone_number: dataForm?.values?.user_phone,
      address: dataForm?.values?.user_address,
      country: getProperty(
        dropdownCountryData,
        "label",
        dataForm?.values?.user_country
      ),
      city: getProperty(dropdownCityData, "label", dataForm?.values?.user_city),
      district: getProperty(
        dropdownDistrictsData,
        "label",
        dataForm?.values?.user_district
      ),
      ward: getProperty(dropdownWardData, "label", dataForm?.values?.user_ward),
      country_id: getProperty(
        dropdownCountryData,
        "value",
        dataForm?.values?.user_country
      ),
      city_id: getProperty(
        dropdownCityData,
        "value",
        dataForm?.values?.user_city
      ),
      district_id: getProperty(
        dropdownDistrictsData,
        "value",
        dataForm?.values?.user_district
      ),
      ward_id: getProperty(
        dropdownWardData,
        "value",
        dataForm?.values?.user_ward
      ),
      country_code: getProperty(
        dropdownCountryData,
        "code",
        dataForm?.values?.user_country
      ),
      city_code: getProperty(
        dropdownCityData,
        "code",
        dataForm?.values?.user_city
      ),
      district_code: getProperty(
        dropdownDistrictsData,
        "code",
        dataForm?.values?.user_district
      ),
      ward_code: getProperty(
        dropdownWardData,
        "code",
        dataForm?.values?.user_ward
      ),
      address_type: addressType,
      is_default: isDefault,
    }
    if( dataForm.isValid && !(!data?.country_id|| !data.city_id|| !data.district_code)){
      const userCountryCode = parsePhoneNumber(dataForm.values.user_phone)?.country?.toLowerCase() || "vn"
      new AddressControllerApi(configHeader(SERVICE.ORDER))
        .addressIdPut(data, userCountryCode, addressId)
        .then(() => {
          handleConfirm(isSelected)
          toast({ detail: capitalizedFirst(t("address-modal.update-success")), severity: "success" })
        })
        .catch(() => {
          toast({ detail: capitalizedFirst(t("address-modal.update-fail")), severity: "warn" })
        })
    }
  }

  useEffect(() => {
    if (dataForm?.values?.user_country) {
      setDropdownCityData([])
      setDropdownDistrictsData([])
      setDropdownWardData([])
      dataForm.setFieldValue("user_city", null)
      dataForm.setFieldValue("user_district", null)
      dataForm.setFieldValue("user_ward", null)
      getCityList(dataForm?.values?.user_country)
    }
  }, [dataForm?.values?.user_country])

  useEffect(() => {
    if (dataForm?.values?.user_city) {
      setDropdownDistrictsData([])
      setDropdownWardData([])
      dataForm.setFieldValue("user_district", null)
      dataForm.setFieldValue("user_ward", null)
      getDistrictList(dataForm?.values?.user_city)
    }
  }, [dataForm?.values?.user_city])
  useEffect(() => {
    if (dataForm?.values?.user_district) {
      setDropdownWardData([])
      dataForm.setFieldValue("user_ward", null)
      getWardList(dataForm?.values?.user_district)
    }
  }, [dataForm?.values?.user_district])
  const isInValidField = (name: string) => {
    return !!(
      dataForm?.touched[`${name}` as keyof typeof dataForm.touched] &&
      dataForm?.errors[`${name}` as keyof typeof dataForm.errors]
    )
  }
  if (!isShow) return null
  
  const footerContent = (
    <div className="mt-5 flex justify-end gap-1">
      <button
        className="flex items-center justify-center rounded-3 border border-gray-400 py-1 px-3 font-medium text-gray-500 transition hover:border-gray-600"
        onClick={() => handleClose()}
      >
        {capitalizedFirst(t("global.cancel"))}
      </button>
      <button
        className={`flex items-center justify-center rounded-3  py-1 px-3 font-medium text-white transition ${
          dataForm.isValid && !(!getProperty(
            dropdownCityData,
            "value",
            dataForm?.values?.user_city
          ) || !getProperty(
            dropdownCityData,
            "value",
            dataForm?.values?.user_city
          )|| !getProperty(
            dropdownDistrictsData,
            "value",
            dataForm?.values?.user_district
          ))
            ? "border-blue-600 bg-blue-600"
            : "border-blue-200 bg-blue-200"
        }`}
        disabled={!dataForm.isValid && !(!getProperty(
          dropdownCityData,
          "value",
          dataForm?.values?.user_city
        ) || !getProperty(
          dropdownCityData,
          "value",
          dataForm?.values?.user_city
        )|| !getProperty(
          dropdownDistrictsData,
          "value",
          dataForm?.values?.user_district
        )) }
        type="submit"
        onClick={handleSumitForm}
      >
        {capitalizedFirst(t("global.confirm"))}
      </button>
    </div>
  )

  return (
    <Dialog
      header={headerCustom}
      visible={isShow}
      onHide={() => handleClose()}
      draggable={false}
      footer={footerContent}
    >
      <form
        onSubmit={dataForm.handleSubmit}
        className="flex w-full sm:w-[500px] md:w-[600px] flex-col p-4"
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-3">
          <div className="flex flex-col">
            <span className="text-14 font-medium leading-20 text-gray-700">
              {capitalizedFirst(t("global.fullName"))}{" "}
              <span className="text-red-500">*</span>
            </span>
            <InputText
              className={`mt-6px rounded-3 border border-gray-300 py-10px px-14px text-16 font-normal leading-24 text-gray-900  ${
                isInValidField("user_name") ? "p-invalid" : ""
              } `}
              value={dataForm?.values.user_name}
              placeholder={capitalizedFirst(t("global.fullName"))}
              onChange={(e) => {
                if (!dataForm?.touched?.user_name)
                  dataForm.setTouched({
                    ...dataForm.touched,
                    ...{ user_name: true },
                  })
                dataForm.setFieldValue("user_name", e?.target?.value)
              }}
            />
            {dataForm.errors.user_name && dataForm.touched.user_name && (
              <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                {dataForm.errors.user_name}
              </span>
            )}
          </div>
          <div className="flex  w-full flex-col gap-1">
            <span className="text-14 font-medium leading-20 text-gray-700">
              {capitalizedFirst(t("global.phoneNumber"))}{" "}
              <span className="text-red-500">*</span>
            </span>
            <div
              className={`flex items-center gap-1 rounded-3 border bg-white px-14px py-10px ${
                isInValidField("user_phone")
                  ? "p-invalid border border-[#e24c4c]"
                  : "border border-gray-200"
              }`}
            >
              <PhoneInput
                className="text-16 font-normal leading-24 text-gray-900"
                placeholder="Enter Phone Number"
                international= {true}
                focusInputOnCountrySelection={true}
                countryCallingCodeEditable={false}
                defaultCountry="VN"
                value={dataForm.values.user_phone}
                onChange={(e) => {
                  if (!dataForm?.touched?.user_phone)
                    dataForm.setTouched({
                      ...dataForm.touched,
                      ...{ user_phone: true },
                    })

                  dataForm.setFieldValue("user_phone", e || null)
                }}
              />
              <div className="hidden">
                <PhoneInputNumber
                  phoneNumber={phoneNumber}
                  updatePhoneNumber={handlePhoneNumber}
                />
              </div>
            </div>
            {dataForm.errors.user_phone && dataForm.touched.user_phone && (
              <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                {dataForm.errors.user_phone}
              </span>
            )}
          </div>
        </div>
        <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-x-3 gap-y-3">
          <div className="flex flex-col">
            <span className="text-14 font-medium leading-20 text-gray-700">
              {capitalizedFirst(t("global.country"))}{" "}
              <span className="text-red-500">*</span>
            </span>
            <Dropdown
              placeholder={capitalizedFirst(t("global.country"))}
              emptyMessage={capitalizedFirst(t("global.no-data-found"))}
              optionLabel="label"
              value={dataForm?.values.user_country}
              options={dropdownCountryData}
              className={`mt-6px flex h-[44px] items-center rounded-3 border border-gray-300 text-16 font-normal leading-24 text-gray-900 $${
                isInValidField("user_country") ? "p-invalid" : ""
              }`}
              onChange={(e) => {
                if (!dataForm?.touched?.user_country)
                  dataForm.setTouched({
                    ...dataForm.touched,
                    ...{ user_country: true },
                  })
                dataForm.setFieldValue("user_country", e.value)
              }}
            />
            {dataForm.errors.user_country && dataForm.touched.user_country && (
              <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                {dataForm.errors.user_country}
              </span>
            )}
          </div>
          <div className="flex flex-col">
            <span className="text-14 font-medium leading-20 text-gray-700">
              {capitalizedFirst(t("global.city"))}{" "}
              <span className="text-red-500">*</span>
            </span>
            <Dropdown
              filter
              placeholder={capitalizedFirst(t("global.city"))}
              emptyMessage={capitalizedFirst(t("global.no-data-found"))}
              value={dataForm?.values?.user_city}
              optionLabel="label"
              options={dropdownCityData}
              className={`mt-6px flex h-[44px] items-center rounded-3 border border-gray-300 text-16 font-normal leading-24 text-gray-900 ${
                isInValidField("user_city") ? "p-invalid" : ""
              }`}
              onChange={(e) => {
                if (!dataForm?.touched?.user_city)
                  dataForm.setTouched({
                    ...dataForm.touched,
                    ...{ user_city: true },
                  })
                dataForm.setFieldValue("user_city", e.value)
              }}
            />
            {dataForm.errors.user_city && dataForm.touched.user_city && (
              <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                {dataForm.errors.user_city}
              </span>
            )}
          </div>
          <div className="flex flex-col">
            <span className="text-14 font-medium leading-20 text-gray-700">
              {capitalizedFirst(t("global.district"))}{" "}
              <span className="text-red-500">*</span>
            </span>
            <Dropdown
              placeholder={capitalizedFirst(t("global.district"))}
              emptyMessage={capitalizedFirst(t("global.no-data-found"))}
              value={dataForm?.values?.user_district}
              optionLabel="label"
              options={dropdownDistrictsData}
              className={`mt-6px flex h-[44px] items-center rounded-3 border border-gray-300 text-16 font-normal leading-24 text-gray-900 ${
                isInValidField("user_district") ? "p-invalid" : ""
              }`}
              onChange={(e) => {
                if (!dataForm?.touched?.user_district)
                  dataForm.setTouched({
                    ...dataForm.touched,
                    ...{ user_district: true },
                  })
                dataForm.setFieldValue("user_district", e.value)
              }}
            />
            {dataForm.errors.user_district &&
              dataForm.touched.user_district && (
              <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                {dataForm.errors.user_district}
              </span>
            )}
          </div>
          <div className="flex flex-col">
            <span className="text-14 font-medium leading-20 text-gray-700">
              {capitalizedFirst(t("global.ward"))}
              <span className="text-red-500"> *</span>
            </span>
            <Dropdown
              placeholder={capitalizedFirst(t("global.ward"))}
              emptyMessage={capitalizedFirst(t("global.no-data-found"))}
              value={dataForm?.values?.user_ward}
              optionLabel="label"
              options={dropdownWardData}
              className="mt-6px flex h-[44px] items-center rounded-3 border border-gray-300 text-16 font-normal leading-24 text-gray-900"
              onChange={(e) => {
                if (!dataForm?.touched?.user_ward)
                  dataForm.setTouched({
                    ...dataForm.touched,
                    ...{ user_ward: true },
                  })
                dataForm.setFieldValue("user_ward", e.value)
              }}
            />
            {dataForm?.values?.user_country &&
            dataForm?.values?.user_city &&
            dataForm?.values?.user_district &&
            dropdownWardData?.length &&
            dataForm.errors.user_ward &&
              dataForm.touched.user_ward ? (
                <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                  {dataForm.errors.user_ward}
                </span>
              ) : null}
            {dataForm?.values?.user_country &&
              dataForm?.values?.user_city &&
              dataForm?.values?.user_district &&
              isNotSupport ? (
                <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                  {capitalizedFirst(t("modal.address-not-support"))}
                </span>
              ) : null}
          </div>
        </div>
        <div className="mt-2 flex flex-col">
          <span className="text-14 font-medium leading-20 text-gray-700">
            {capitalizedFirst(t("global.address"))}{" "}
            <span className="text-red-500">*</span>
          </span>
          <InputText
            className={`mt-6px w-[100%] rounded-3 border border-gray-300 py-10px px-14px text-16 font-normal leading-24 text-gray-900  ${
              isInValidField("user_address") ? "p-invalid" : ""
            }`}
            value={dataForm.values.user_address}
            placeholder={capitalizedFirst(t("global.address"))}
            onChange={(e) => {
              if (!dataForm?.touched?.user_address)
                dataForm.setTouched({
                  ...dataForm.touched,
                  ...{ user_address: true },
                })
              dataForm.setFieldValue("user_address", e?.target?.value)
            }}
          />
          {dataForm.errors.user_address && dataForm.touched.user_address && (
            <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
              {dataForm.errors.user_address}
            </span>
          )}
        </div>
        <div className="mt-2 flex flex-col">
          <span className="text-14 font-medium leading-20 text-gray-700">
            {capitalizedFirst(t("global.addressType"))}
          </span>
          <div className="mt-6px flex text-14 font-medium leading-20">
            <div
              onClick={() => setAddressType(AddressAddressTypeEnum.HOME)}
              className={`flex cursor-pointer items-center rounded-5 py-4px px-2 ${
                addressType === ADDRESS_TYPE.HOME ? "bg-blue-50" : "bg-gray-200"
              }`}
            >
              <i className={`sctr-icon-home-smile text-14 font-medium ${addressType === ADDRESS_TYPE.HOME
                ? "text-blue-700"
                : "text-gray-700"} `}></i>
              
              <span
                className={`ml-4px ${
                  addressType === ADDRESS_TYPE.HOME
                    ? "text-blue-700"
                    : "text-gray-700"
                }`}
              >
                {capitalizedFirst(t("global.home"))}
              </span>
            </div>
            <div
              onClick={() => setAddressType(AddressAddressTypeEnum.OFFICE)}
              className={`ml-1 flex cursor-pointer items-center rounded-5 py-4px px-2 ${
                addressType === ADDRESS_TYPE.OFFICE
                  ? "bg-blue-50"
                  : "bg-gray-200"
              }`}
            >
              <i className={`sctr-icon-building-07 text-14 font-medium ${addressType === ADDRESS_TYPE.OFFICE
                ? "text-blue-700"
                : "text-gray-700"}`}></i>
              <span
                className={`ml-4px ${
                  addressType === ADDRESS_TYPE.OFFICE
                    ? "text-blue-700"
                    : "text-gray-700"
                }`}
              >
                {capitalizedFirst(t("global.office"))}
              </span>
            </div>
          </div>
        </div>
        <div className="mt-2 flex items-center">
          <Checkbox
            checked={isDefault}
            onChange={(e) => setIsDefault(e.checked as boolean)}
          />
          <span className="ml-1 text-14 font-medium leading-20 text-gray-700">
            {capitalizedFirst(t("modal.setDefault"))}
          </span>
        </div>
        
      </form>
    </Dialog>
  )
}
