import { ClaimVoucherControllerApi } from "@soctrip/axios-promotion-service"
import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "src/app/store"
import ImgCustom from "src/components/img-custom"
import { configHeader } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import { useToast } from "src/hooks/use-toast"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { mathRoundNumber } from "src/utils/common"
import { convertCurrency } from "src/utils/convert-currency"
import { DealVoucherType, PingProductType } from "../types"
import { CartControllerApi } from "@soctrip/axios-cart-service"
import { setBuyLiveStream, setBuyNowId } from "src/features/shopping-cart"

const DealVoucher = (props: DealVoucherType) => {
  const {
    id,
    voucherImg,
    reward,
    voucherName,
    minSpend,
    isClamp,
    updateClaimData,
    isRoom,
    discountType,
    isShopOwner,
    isDisplay,
    endTime,
    clearCurrentVoucher,
    pingProduct,
    campaign_id,
    title,
  } = props
  const { t } = useTranslation()
  const authStore = useSelector((state: RootState) => state.auth)
  const dispatch = useDispatch()
  const [time, setTime] = useState<number | undefined>(undefined)
  const [isReadyTime, setIsReadyTime] = useState<boolean>(false)
  const showToast = useToast()
  const handleBuyNow = () => {
    const reqData = {
      object_type: "PRODUCT",
      entity_id: pingProduct?.shopId || "",
      object_id: pingProduct?.productId || "",
      stock_id: pingProduct?.stockId || "",
      quantity: 1,
      token:  pingProduct?.token || "",
    }
    new CartControllerApi(configHeader(SERVICE.CART))
      .cartsPost(reqData)
      .then((res) => {
        if (res?.data?.success) {
          const url = `${process.env.REACT_APP_ECOMMERCE_DOMAIN_URL}/shopping/order-cart`
          // const url = "http://localhost:4200/shopping/order-cart"
          dispatch(setBuyNowId(pingProduct?.stockId || ""))
          dispatch(setBuyLiveStream(pingProduct as PingProductType || undefined))
          if(endTime) window.open(url, "_blank")
        }
      })
      .catch(() =>
        showToast({
          detail: "Add product to cart fail",
          severity: "error",
        })
      )
  }

  // on modal
  const clampVoucher = () => {
    if (authStore?.value) {
      //todo
      new ClaimVoucherControllerApi(configHeader(SERVICE.PROMOTION))
        .createNewOne2({ voucher_id: id, live_campaign_id: campaign_id })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          if (res?.data?.data) {
            if (updateClaimData) updateClaimData(id)
            showToast({
              detail: capitalizedFirst(t("live-stream.claim-voucher-success")),
              severity: "success",
            })
          }
        })
        .catch(() => {
          showToast({
            detail: capitalizedFirst(t("live-stream.claim-voucher-failed")),
            severity: "error",
          })
        })
    } else {
      showToast({ detail: "Please login before continue", severity: "warn" })
    }
  }

  const handleSave = () => {
    if(isRoom) {
      if (authStore?.value) {
        const end = new Date(endTime || "").getTime()
        const now = new Date().getTime()
        let timeValue = 0
        if(end - now >=0) {
          timeValue = Math.round((end - now)/1000)
        }
        // clamp voucher
        new ClaimVoucherControllerApi(configHeader(SERVICE.PROMOTION))
          .createNewOne2({ voucher_id: id, live_campaign_id: campaign_id, display_time: timeValue, end_at: endTime || "" })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .then((res: any) => {
            if (res?.data?.data) {
              if (updateClaimData) updateClaimData(id)
              if(pingProduct?.shopId && endTime) {
                handleBuyNow()
              } else {
                showToast({
                  detail: capitalizedFirst(t("live-stream.claim-voucher-success")),
                  severity: "success",
                })
              }
            }
          })
          .catch(() => {
            showToast({
              detail: capitalizedFirst(t("live-stream.claim-voucher-failed")),
              severity: "error",
            })
          })
      } else {
        showToast({ detail: "Please login before continue", severity: "warn" })
      }
    } else {
      clampVoucher()
    }
  }

  const getValueReward = useMemo(() => {
    if(discountType === "PERCENTAGE") {
      return `${mathRoundNumber(reward)}%`
    } else {
      return convertCurrency(reward)
    }
  }, [])

  // check valid time of voucher
  const isValidTime = useMemo(() => {
    const result = true
    if(!isDisplay) return false
    if(endTime) {
      const end = new Date(endTime).getTime()
      const now = new Date().getTime()
      if(end - now <= 0) return false
    }
    return result
  }, [endTime])

  // init time
  useMemo(() => {
    if(isRoom && isValidTime) {
      if(endTime) {
        const end = new Date(endTime).getTime()
        const now = new Date().getTime()
        if(end - now >=0) {
          const timeValue = Math.round((end - now)/1000)
          setTime(timeValue)
        }
      }
      setIsReadyTime(true)
    }
  }, [isValidTime, isRoom])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let interval: any = undefined
    if (isRoom && isValidTime && isReadyTime && time) {
      interval = setInterval(() => {
        if (time) setTime(time - 1)
        else {
          clearInterval(interval)
          if (clearCurrentVoucher) clearCurrentVoucher()
        }
      }, 1000)
    }
    return () => {
      if (interval) clearInterval(interval)
    }
  }, [isRoom, time, isValidTime, isReadyTime])

  const getVoucherName = useMemo(() => {
    let result = voucherName
    if(result.length > 19) {
      result = result.slice(0,16) + "..."
    }
    return result
  }, [voucherName])

  if (isRoom && !isValidTime) return null
  return (
    <div className="flex w-full rounded-3 border border-orange-dark-300 bg-white">
      <div className="flex items-center justify-center p-4px">
        <div className="h-[40px] w-[40px]">
          <ImgCustom
            alt="voucherImg"
            url={voucherImg}
            className="h-full w-full"
          />
        </div>
      </div>
      <div className="my-3 border-r border-dashed border-orange-dark-300"></div>
      <div className="flex flex-1 p-4px gap-1">
        <div className="flex flex-1 flex-col">
          {
            title ? (
              <>
                <span className="text-[10px] font-semibold leading-[14px] text-gray-700 line-clamp-2">
                  {title}
                </span>
              </>
            ) : null
          }
          <span className="text-[10px] font-semibold leading-[14px] text-gray-700 line-clamp-2">
            {`${capitalizedFirst(t("live-stream.reward", { value: getValueReward }))}`}
          </span>
          <span
            className="target-voucher-name text-[10px] leading-[14px] text-gray-500 line-clamp-1 cursor-pointer">
            {getVoucherName}
          </span>          
          <span className="text-[10px] font-medium leading-[14px] text-gray-700 ">
            {capitalizedFirst(
              t("live-stream.min-spend", {
                value: convertCurrency(minSpend || 0),
              })
            )}
          </span>
        </div>
        {
          !isClamp ? (
            <div className="flex flex-col items-center justify-center">
              {
                !isShopOwner ? (
                  <button
                    disabled={(isClamp && !endTime)}
                    onClick={handleSave}
                    className={`cursor-pointer flex items-center justify-center rounded-3 bg-blue-600 p-4px text-[10px] font-semibold leading-[14px] text-white hover:bg-blue-500  ${
                      isClamp && !endTime ? "opacity-50" : ""
                    }`}
                  >
                    {
                      isRoom && endTime ? capitalizedFirst(t("live-stream.use")) : capitalizedFirst(t( isClamp ? "live-stream.saved" : "live-stream.save"))
                    }
                  </button>
                ) : null
              }          
              {isRoom && isValidTime && time? (
                <span className="mt-4px text-[10px] font-medium leading-[14px] text-orange-dark-600">
                  {capitalizedFirst(
                    t("live-stream.voucher-countdown", { value: time })
                  )}
                </span>
              ) : null}
            </div>
          ) : (
            <div className="w-full w-[20px]">
            </div>
          )
        }

      </div>
    </div>
  )
}

export default DealVoucher
