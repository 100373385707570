import React from "react"
import { ShopDataProps } from "../approve-info-view/type"
import { RadioButton } from "primereact/radiobutton"
import { TypeEnum } from "../request-open-view/types"
import ImgCustom from "../img-custom"
import { IMAGE_TYPE } from "src/constants/common"
import { useTranslation } from "react-i18next"
import { useToast } from "src/hooks/use-toast"

export default function ApproveVerifyInformation(props: ShopDataProps) {
  const { certificate_type, certificate, other_document, note } = props

  const { t } = useTranslation()
  const showToast = useToast()
  const downloadOtherDocument = async (id: string, extension: string, originalName: string) => {
    const url = `${process.env.REACT_APP_API_URL}/storage/files/download/${id}${extension}`

    try {
      const response = await fetch(url)
      if (!response.ok) {
        showToast({
          detail: t("request-open-page.failed-fetch"),
          severity: "error",
        })
        return
      }

      const blob = await response.blob()
      if (!blob || blob.size === 0) {
        showToast({
          detail: t("request-open-page.failed-fetch"),
          severity: "error",
        })
        return
      }

      const link = document.createElement("a")
      link.href = window.URL.createObjectURL(blob)
      link.download = originalName

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className="w-full p-3">
      <div className="flex w-full flex-col lg:flex-row">
        {/* Title */}
        <div className="flex-1">
          <div className="flex flex-col">
            <span className="leading-28px text-[18px] font-semibold text-gray-900">
              {"Verified Information"}
            </span>
          </div>
        </div>
        {/* Content */}
        <div className="flex w-full flex-col gap-2 lg:w-2/3">
          {/* Type */}
          <div className="flex w-full flex-col gap-1">
            <div className="text-14 font-medium leading-20 text-gray-700">Type</div>
            <div className="flex gap-5">
              <div className="flex items-center">
                <RadioButton
                  inputId="type-personal"
                  name="type"
                  checked={certificate_type == TypeEnum.personal}
                />
                <label htmlFor="type-personal" className="ml-1 text-14 font-medium">
                  Personal
                </label>
              </div>
              <div className="flex items-center">
                <RadioButton
                  inputId="type-business"
                  name="type"
                  value={TypeEnum.business}
                  checked={certificate_type == TypeEnum.business}
                />
                <label htmlFor="type-business" className="ml-1 text-14 font-medium">
                  Business
                </label>
              </div>
            </div>
          </div>
          {/* ID Card */}
          {certificate_type === TypeEnum.personal ? (
            <>
              <div className="flex flex-col gap-1">
                <div className="text-14 font-medium leading-20 text-gray-700">ID Card</div>
                <div className="flex flex-col gap-3 sm:flex-row">
                  {/* Front */}
                  <div className="flex-1 rounded-3 bg-gray-100 px-3 py-2">
                    <div className="mb-2 text-center text-14 font-medium">Front</div>
                    <div className="max-h-[220px]">
                      <ImgCustom
                        url={certificate?.length ? certificate[0].id : ""}
                        alt="Front image"
                        className="max-h-[220px] w-full"
                        type={IMAGE_TYPE.WEB}
                      />
                    </div>
                  </div>
                  {/* Back */}
                  <div className="flex-1 rounded-3 bg-gray-100 px-3 pt-2">
                    <div className="mb-2 text-center text-14 font-medium">Back</div>
                    <div className="max-h-[220px]">
                      <ImgCustom
                        url={certificate?.length ? certificate[1].id : ""}
                        alt="Front image"
                        className="max-h-[220px] w-full"
                        type={IMAGE_TYPE.WEB}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {/* Order document */}
          {certificate_type === TypeEnum.business || other_document?.length ? (
            <div className="flex w-full flex-col gap-1">
              <div className="text-14 font-medium">
                {certificate_type === TypeEnum.personal ? "Other document" : "Business document"}
              </div>

              <div className="flex w-full flex-col gap-2">
                {other_document?.map((document) => (
                  <div key={document.id} className="flex items-start gap-2 rounded-3 border p-3">
                    <div className="flex h-32px w-32px items-center justify-center rounded-full bg-gray-100">
                      <i className="sctr-icon-file-05"></i>
                    </div>
                    <div className="flex-1 text-14 font-medium">
                      <div className="text-gray-900">{document.original_name}</div>
                      <div className="text-gray-500">{document.size} KB</div>
                    </div>
                    <div className="flex h-full cursor-pointer items-center pt-2">
                      <i
                        className="sctr-icon-download-02 hover:text-blue-light-600"
                        onClick={() =>
                          downloadOtherDocument(
                            document.id,
                            document.extension,
                            document.original_name
                          )
                        }
                      ></i>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : null}

          {/* Note */}
          <div className="flex flex-col gap-1">
            <div className="text-14 font-medium text-gray-700 leading-20">Note</div>
            <div className="text-14">
              {note || "--"}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
