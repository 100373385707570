export const ROUTERS = {
  HOME: "",
  BOOKING: "/booking",
  JOURNEY: "/journey",
  SHOPPING: "/shopping",
  CHECKOUT: "/checkout",
  FOOD: "/food",
  SHOPPING_CART: "/shopping-cart",
  danger: "DANGER",
  custom: "CUSTOM",
  PRODUCT_DETAIL: ":name/:id/:productCode",
  SHOPPING_MERCHANT: "merchant/:name/:id/:shopCode",
  SHOPPING_CAMPAIGN_DETAIL: "campaign/:name/:shopId/:campaignId",
  SHOPPING_SEARCH: "search",
  SHOPPING_HOT_VOUCHER: "hotvoucher",
  SHOPPING_FREE_SHIP: "freeship",
  SHOPPING_TREND_DEALS: "trenddeals",
  PURCHASE_HISTORY: "orders",
  ORDER_DETAILS: "order-details/:id",
  MERCHANT_SEARCH: "merchant-page/:id/search",
  MERCHANT_CATEGORIES: "merchant-page/categories",
  ORDER_CART: "order-cart",
  REQUEST_PAGE: "request-open-shop",
  SHARE_TOKEN: "/share-token",
  FLASH_SALE: "flash-sale",
  MOST_PAGE: "most-page",
  LIVESTREAM: "livestream",
  LIVESTREAM_ROOM: "livestream/room/:id",
  LIVE_STREAM_ROOM_PATH: "livestream/room",
  APPROVAL_REQUEST: "/approval-request/:id",
  NOT_FOUND: "/not-found",
  REFUND_REQUEST: "refund-request/:id",
  REFUND_DETAIL: "refund-detail/:id",
  IFRAME_APPROVE_SHOP: "/approval-request/shop/:id",
  WELCOME: "/welcome",
  PROMOTION: "promotion/:promotionName",
}

export const ROUTERS_NOT_LAYOUT = [
  ROUTERS.SHARE_TOKEN,
  ROUTERS.REQUEST_PAGE,
  ROUTERS.LIVE_STREAM_ROOM_PATH,
  ROUTERS.WELCOME,
]

export const ROUTER_PRIVATE = [
  "order-cart",
  "orders",
  "order-details",
  "request-open-shop",
  "refund-request",
  "refund-detail",
]
