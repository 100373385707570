/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react"
import { configHeader } from "src/config/interceptors"
import { ORDER_FAIL_CODE, ORDER_FAIL_MESSAGE, SERVICE } from "src/constants/common"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { StockNotificationBody } from "../product-detail-custom/props"
import { StockNotificationBuilderDTO, StockNotificationControllerApi } from "@soctrip/stock-service"
import { useToast } from "src/hooks/use-toast"
import { t } from "i18next"
import { useDispatch, useSelector } from "react-redux"
import Bell from "src/assets/images/bell-ringing-icon.svg"
import InputNumberCustom from "src/components/input-number"
import { RootState } from "src/app/store"
import { useNavigate } from "react-router-dom"
import EmptyPage from "src/pages/empty-page"
import { ProductDetailActionControlProps } from "./props"
import { setBuyNowId } from "src/features/shopping-cart"
import CreateAccountModal from "src/components/modal/create-account-modal"
import { handleClickSponsors } from "src/services/sponsor-service"
import { Skeleton } from "primereact/skeleton"
import { gaPostAction, TAG_TYPE } from "src/utils/gtag-instance"
import { mathRoundNumber } from "src/utils/common"
import { addProductToCart } from "src/services/cart-service"
import joinShopIcon from "src/assets/images/join-shop-second-.svg"
import joinArrowIcon from "src/assets/images/join-arrow-icon.svg"

export function ProductDetailActionControls(props: ProductDetailActionControlProps) {
  const {
    variationValue,
    shopId,
    stocks,
    variationFirst,
    variationSecond,
    stockData,
    updateProductPrice,
    isShopOwner,
    isRender,
    productName,
    isTicket,
    limitNumber,
    isJoin,
    isSpecialShop,
    openJoinModal,
    isHaveBuySpecialShop,
  } = props
  const handleTranslate = (text: any) => {
    return t(text)
  }
  const authStore = useSelector((state: RootState) => state.auth)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const showToast = useToast()

  const toast = useToast()
  const [quantity, setQuantity] = useState<number>(0)
  const [isShowRegisterModal, setIsShowRegisterModal] = useState<boolean>(false)

  useEffect(() => {
    setQuantity(1)
  }, [variationFirst, variationSecond])
  useEffect(() => {
    if(variationValue){
      if(updateProductPrice) updateProductPrice(quantity * variationValue?.price || 0)
    }
  }, [quantity, variationValue])

  const getDataAddToCart = () => {
    const result = {
      isHaveData: false,
      data: {
        object_type: "PRODUCT",
        entity_id: shopId,
        object_id: "",
        stock_id: "",
        quantity: quantity,
      },
    }

    if (stockData) {
      result.isHaveData = true
      result.data.object_id = stockData?.product_id || ""
      result.data.stock_id = stockData?.id || ""
    }
    return result
  }
  const handleClickAddToCart = (isBuyNow = false) => {
    const reqData = getDataAddToCart()
    if (reqData.isHaveData) {
      if (authStore.value) callApiAddToCart(reqData, isBuyNow)
      else {
        setIsShowRegisterModal(true)
      }
    }
  }

  const handleInteraction = async () => {
    const dto = {
      platform: "Web",
      screenSize: "1920x1080",
      createdAt: Date.now(),
      sessionId: "",
      dataArray: [
        {
          m: "Shop",
          c: "SH",
          a: "C",
          d: shopId,
          co: 1,
          l: "Successful",
        },
        {
          m: "Shop",
          c: "P",
          a: "C",
          d: stocks?.[0]?.id,
          co: 1,
          l: "Successful",
        },
      ],
    }
    await handleClickSponsors(dto)
      .then(() => {
        console.log("")
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const callApiAddToCart = (reqData: any, isBuyNow = false) => {
    //GA POST ACTION
    gaPostAction(TAG_TYPE.ADD_TO_CART, {
      currency: "USD",
      value: mathRoundNumber((stockData?.price_after_tax || 0) * quantity),
      items: [
        {
          item_id: reqData?.data?.object_id,
          item_name: productName,
          price: mathRoundNumber(stockData?.price_after_tax || 0),
          quantity: quantity,
        },
      ],
    })
    handleInteraction()
    addProductToCart(reqData?.data).then(() => {
      if (isBuyNow) {
        dispatch(setBuyNowId(reqData?.data?.stock_id || ""))
        navigate("/shopping/order-cart")
      } else {
        toast({
          detail: capitalizedFirst(t("product-details.add-to-cart-success-message")),
          severity: "success",
        })
      }
    }).catch(err => {
      const errCode = err?.response?.data?.error?.code ? err?.response?.data?.error?.code : ""
      let messageError = "product-details.add-to-cart-failed-message"
      if(errCode) {
        if(ORDER_FAIL_CODE.includes(errCode)) {
          messageError = `order-fail-message.${err?.data.response.data.error.code}`
        } else if(errCode === 400) {
          // product block case
          if(err?.response?.data?.error?.message === ORDER_FAIL_MESSAGE.PRODUCT_BLOCK) {
            messageError = "order-fail-message.product-block"
          }
        } else {
          messageError = err?.response?.data?.error?.message || ""
        }
      }
      toast({
        detail: capitalizedFirst(t(messageError)),
        severity: "error",
      })
    })
  }

  const notifyProductOutStock = () => {
    if (configHeader(SERVICE.STOCK).accessToken != "") {
      const body: StockNotificationBody = !variationValue
        ? {
            product_id: stocks[0]?.product_id as string,
            stock_id: stocks[0]?.id as string,
          }
        : {
            product_id: variationValue?.product_id as string,
            stock_id: variationValue?.id as string,
          }
      new StockNotificationControllerApi(configHeader(SERVICE.STOCK))
        .createNewNotification(body as StockNotificationBuilderDTO)
        .then(() => {
          toast({
            detail: capitalizedFirst(t("product-details.notify-out-stock")),
            severity: "success",
          })
        })
    } else
      showToast({
        detail: "Please login in to receive product information",
        severity: "warn",
      })
  }

  if(!isShopOwner && isSpecialShop && !isJoin) return (
    <>
    {/* NEED JOIN SHOP */}
    <div className="flex flex-col bg-blue-50 rounded-3 px-[16px] py-[12px]">
      <div className="flex items-center gap-1">
        <img className="w-[20px] h-[20px]" src={joinShopIcon} alt="joinShopIcon"/>
        <span className="text-[14px] leading-[20px] text-grey-900">{t("join-shop.store-info")}</span>
      </div>
      <div onClick={() => {openJoinModal()}} className="w-fit mt-[8px] ml-[36px] flex items-center gap-[10px] rounded-3 px-[20px] py-[10px] bg-blue-600 cursor-pointer hover:opacity-[70]">
        <button className="text-[14px] leading-[20px] text-white">{t("join-shop.store-join")}</button>
        <img className="w-[20px] h-[20px]" src={joinArrowIcon} alt="join-icon"/>
      </div>
    </div>
  </>
  )
  
  if(!isShopOwner && isSpecialShop && isJoin && !isHaveBuySpecialShop) return (
    <div className="flex w-full items-center px-3 pb-3">
      <div className="flex items-start gap-1 rounded-3 border border-amber-600 bg-amber-50 p-2">
        <div className="flex h-full items-center">
          <svg
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.30967 0.809999C8.63213 1.10481 8.47359 1.59046 8.08701 2.24419L1.09164 14.0718C0.703614 14.7279 0.350078 15.1042 0.427154 15.828C0.477142 16.2971 0.73016 16.7245 1.11988 17.002C1.7212 17.43 2.23094 17.3207 3.00536 17.3207H16.9959C17.7704 17.3207 18.2802 17.4298 18.8814 17.002C19.271 16.7246 19.5225 16.2972 19.5725 15.828C19.6495 15.1042 19.2977 14.7279 18.9097 14.0718L11.9143 2.24419C11.5277 1.59049 11.3691 1.10488 10.6916 0.809999C10.2521 0.618718 9.74931 0.618627 9.30967 0.809999ZM10.0007 2.33193C9.82764 2.25659 10.0561 2.42463 10.4426 3.07827L17.438 14.9059C17.826 15.562 17.8618 15.8408 17.8815 15.6555C18.0354 15.546 17.7706 15.6555 16.9961 15.6555H3.00554C2.2311 15.6555 1.96609 15.5459 2.12013 15.6555C2.13986 15.8408 2.17565 15.562 2.56367 14.9059L9.55904 3.07827C9.94559 2.42463 10.1737 2.25666 10.0007 2.33193ZM10.0007 6.08153C9.77516 6.08153 9.55882 6.16924 9.39931 6.32537C9.2398 6.4815 9.15019 6.69326 9.15019 6.91406V10.2441C9.15019 10.4649 9.2398 10.6767 9.39931 10.8328C9.55882 10.989 9.77516 11.0767 10.0007 11.0767C10.2263 11.0767 10.4427 10.989 10.6022 10.8328C10.7617 10.6767 10.8513 10.4649 10.8513 10.2441V6.91406C10.8513 6.69326 10.7617 6.4815 10.6022 6.32537C10.4427 6.16924 10.2263 6.08153 10.0007 6.08153ZM10.0007 12.7417C9.77516 12.7417 9.55882 12.8294 9.39931 12.9856C9.2398 13.1417 9.15019 13.3534 9.15019 13.5742C9.15019 13.795 9.2398 14.0068 9.39931 14.1629C9.55882 14.319 9.77516 14.4068 10.0007 14.4068H10.0091C10.2346 14.4068 10.451 14.319 10.6105 14.1629C10.77 14.0068 10.8596 13.795 10.8596 13.5742C10.8596 13.3534 10.77 13.1417 10.6105 12.9856C10.451 12.8294 10.2346 12.7417 10.0091 12.7417H10.0007Z"
              fill="#DC6803"
            />
          </svg>
        </div>
        <div className="text-14 font-medium leading-5 text-gray-700">
        {capitalizedFirst(handleTranslate("join-shop.can-not-buy"))}
        </div>
      </div>
    </div>
  ) 
  return (
    <>
      {!variationValue && !stocks.length ? (
        <div className="h-auto w-full">
          <EmptyPage message="Not found data. Please try again later." />
        </div>
      ) : (
        <div>
          <div className="w-full">
            {(!variationValue && !stocks.length) ||
            (!variationValue?.quantity && !stocks.length) ||
            !stocks.length ||
            variationValue?.quantity == 0 ||
            (!variationValue && !stocks[0]?.quantity) ? (
              <div className="pb-3">
                <div className="flex flex-col items-start gap-1 self-stretch py-1">
                  <span className="text-16 font-semibold leading-7 text-red-500">
                    {capitalizedFirst(t("global.out-stock"))}
                  </span>
                </div>
                <div className="flex w-full items-start gap-4 self-stretch py-1">
                  <button
                    type="button"
                    className="flex w-full items-center justify-center gap-1 rounded-3 border border-gray-300 bg-white py-2 px-20px hover:bg-gray-100"
                    onClick={notifyProductOutStock}
                  >
                    <img src={Bell} alt="bell" />
                    <span className="text-16 font-semibold leading-24 text-gray-700">
                      {capitalizedFirst(t("global.notify-when-in-stock"))}
                    </span>
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex w-full flex-row items-center justify-between  py-2">
                <div className="flex flex-row items-center gap-2 sm:gap-4">
                  <div className="flex items-center text-14 h-full font-medium text-gray-700">
                    {capitalizedFirst(handleTranslate("global.quantity"))}
                  </div>
                  <div className="flex items-center gap-2 ">
                  <div id="custom-input-number" className="w-[120px]">
                    <InputNumberCustom
                      value={quantity}
                      updateValue={(newValue) => setQuantity(newValue)}
                      min={1}
                      max={isTicket && limitNumber > 0 ? limitNumber : variationValue?.quantity || stocks[0]?.quantity || 0}
                    />
                  </div>
                </div>
                  <div className="flex flex-row gap-4px text-12 font-medium text-gray-700">
                    <span className="text-sm font-medium text-gray-700">{t("product-details.in-stock")}:</span>
                    <span className="text-sm font-medium text-gray-700">{variationValue?.quantity || stocks[0]?.quantity || 0}</span>
                  </div>
                </div>

              </div>
            )}
          </div>
          {/* BUY VIEW */}
          {(!variationValue && !stocks.length) ||
          (!variationValue?.quantity && !stocks.length) ||
          !stocks.length ||
          variationValue?.quantity == 0 ||
          (!variationValue && !stocks[0]?.quantity) ? null : (
            !isRender ?<div><Skeleton height="4rem" className="mb-2"></Skeleton></div> :
            <>
            {isShopOwner ? (
              <div className="flex w-full items-center px-3 pb-3">
                <div className="flex items-start gap-1 rounded-3 border border-amber-600 bg-amber-50 p-2">
                  <div className="flex h-full items-center">
                    <svg
                      width="20"
                      height="18"
                      viewBox="0 0 20 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.30967 0.809999C8.63213 1.10481 8.47359 1.59046 8.08701 2.24419L1.09164 14.0718C0.703614 14.7279 0.350078 15.1042 0.427154 15.828C0.477142 16.2971 0.73016 16.7245 1.11988 17.002C1.7212 17.43 2.23094 17.3207 3.00536 17.3207H16.9959C17.7704 17.3207 18.2802 17.4298 18.8814 17.002C19.271 16.7246 19.5225 16.2972 19.5725 15.828C19.6495 15.1042 19.2977 14.7279 18.9097 14.0718L11.9143 2.24419C11.5277 1.59049 11.3691 1.10488 10.6916 0.809999C10.2521 0.618718 9.74931 0.618627 9.30967 0.809999ZM10.0007 2.33193C9.82764 2.25659 10.0561 2.42463 10.4426 3.07827L17.438 14.9059C17.826 15.562 17.8618 15.8408 17.8815 15.6555C18.0354 15.546 17.7706 15.6555 16.9961 15.6555H3.00554C2.2311 15.6555 1.96609 15.5459 2.12013 15.6555C2.13986 15.8408 2.17565 15.562 2.56367 14.9059L9.55904 3.07827C9.94559 2.42463 10.1737 2.25666 10.0007 2.33193ZM10.0007 6.08153C9.77516 6.08153 9.55882 6.16924 9.39931 6.32537C9.2398 6.4815 9.15019 6.69326 9.15019 6.91406V10.2441C9.15019 10.4649 9.2398 10.6767 9.39931 10.8328C9.55882 10.989 9.77516 11.0767 10.0007 11.0767C10.2263 11.0767 10.4427 10.989 10.6022 10.8328C10.7617 10.6767 10.8513 10.4649 10.8513 10.2441V6.91406C10.8513 6.69326 10.7617 6.4815 10.6022 6.32537C10.4427 6.16924 10.2263 6.08153 10.0007 6.08153ZM10.0007 12.7417C9.77516 12.7417 9.55882 12.8294 9.39931 12.9856C9.2398 13.1417 9.15019 13.3534 9.15019 13.5742C9.15019 13.795 9.2398 14.0068 9.39931 14.1629C9.55882 14.319 9.77516 14.4068 10.0007 14.4068H10.0091C10.2346 14.4068 10.451 14.319 10.6105 14.1629C10.77 14.0068 10.8596 13.795 10.8596 13.5742C10.8596 13.3534 10.77 13.1417 10.6105 12.9856C10.451 12.8294 10.2346 12.7417 10.0091 12.7417H10.0007Z"
                        fill="#DC6803"
                      />
                    </svg>
                  </div>
                  <div className="text-14 font-medium leading-5 text-gray-700">
                  {capitalizedFirst(handleTranslate("product-details.you-cannot-purchase-products"))}
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-row gap-3 pt-2">
                {
                  <>
                    {!isTicket ? (
                      <>
                        <button
                          type="button"
                          onClick={() => handleClickAddToCart()}
                          className="flex h-[44px] items-center justify-center gap-2  rounded-3 border bg-white px-20px py-2 max-w-[240px]"
                        >
                          <i className="pi pi-shopping-cart" />
                          <p className="text-14 font-semibold leading-5 text-gray-700">
                            {capitalizedFirst(handleTranslate("global.add-to-cart"))}
                          </p>
                        </button>
                      </>
                    ) : null}
                    <button
                      type="button"
                      onClick={() => handleClickAddToCart(true)}
                      className="rounded-3 bg-blue-600 px-20px py-2 flex-1 max-w-[200px]"
                    >
                      <p className="text-14 font-semibold leading-5 text-white">
                        {capitalizedFirst(handleTranslate("global.buy-now"))}
                      </p>
                    </button>
                  </>
                }
              </div>
            )}
          </>
          )}
        </div>
      )}
      {isShowRegisterModal ? (
        <CreateAccountModal
          isShow={isShowRegisterModal}
          handleClose={() => setIsShowRegisterModal(false)}
        />
      ) : null}
    </>
  )
}
