import React, { useEffect, useRef, useState } from "react"
import { useParams } from "react-router"
import EmptyPage from "src/pages/empty-page"
import { RootState } from "src/app/store"
import { useSelector } from "react-redux"
import { getBloomFilter } from "src/services/bookmark-service"
import ProductViews from "./products-view"
import useOnScreen from "src/hooks/use-on-screen"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import SkeletonLoading from "src/components/skeleton-loading"
import { getCampaignProducts } from "src/services/home-connect-service"
import { CustomCardProps } from "src/components/card-custom/card-custom/custom-card-props"

export default function CampaignProduct() {
  const { campaignId, shopId, name} = useParams()
  const loadingRef = useRef(null)
  const { t } = useTranslation()
  const isOnScreen = useOnScreen(loadingRef)
  const authStore = useSelector((state: RootState) => state.auth)
  const [loading, setLoading] = useState<boolean>(true)
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
  const [pageIndex, setPageIndex] = useState<number>(0)
  const [totalElement, setTotalElement] = useState<number>(0)
  const [handleShowMoreLoading, setHandleShowMoreLoading] =
    useState<boolean>(false)
  const [products, setProducts] = useState<CustomCardProps[]>([])
  const [bloomData, setBloomData] = useState<string>("")
  
  const handleGetAllProducts = async () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getCampaignProducts(shopId || "", campaignId ||"", pageIndex).then((res:any) => {
      if(res?.data?.data) {
        setTotalElement(res?.data?.data?.totalElement)
        if(res?.data?.data?.data?.length) {
          const result = [] as CustomCardProps[]
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.data.forEach((i:any) => {
            result.push({
              avatar: i?.avatar || "",
              shop_id: shopId,
              name: i?.name || "",
              shop_name: name,
              id: i?.id || "",
              productName: i?.name || "",
              price_after_tax: i?.price_after_tax || 0,
              original_price_after_tax: i?.original_price_after_tax ||0,
              productId: i?.id || "",
              point: i?.point || 0,
              sold: i?.sold || 0,
              typeCard: "PRODUCT",
              sub_label: i?.supplier_name || "",
            })
          })
          setProducts([...products, ...result])
          setHandleShowMoreLoading(false)
        }
      }
    })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((err:any) => console.log(err)).finally(() => {
        setLoading(false)
        if(handleShowMoreLoading) setHandleShowMoreLoading(false)
      })
  }
  
  useEffect(() => {
    handleGetAllProducts()
    setIsFirstLoad(false)
  },[])

  const handleReloadData = async () => {
    handleGetBloomData()
  }

  // get bloom data
  const handleGetBloomData = async () => {
    if (authStore?.value) {
      await getBloomFilter()
        .then((response) => {
          setBloomData(response?.data?.data?.bloom_filter)
        })
        .catch((error) => error)
    }
  }

  const handleLoadMore = () => {
    setHandleShowMoreLoading(true)
    setPageIndex(pageIndex + 1)
  }


  useEffect(() => {
    if (isOnScreen && !isFirstLoad && products?.length < totalElement && totalElement > 15) {
      handleLoadMore()
    }
  }, [isOnScreen])
  
  useEffect(() => {
    if (pageIndex && !isFirstLoad) handleGetAllProducts()
  }, [pageIndex])

  return (
    <div className="w-full">
      {loading ? (
        <div>
          <SkeletonLoading limit={5} />
        </div>
      ) : (
        <>
          {products?.length ? (
            <div className="flex w-full flex-col">
              <div className="flex w-full flex-col gap-1">
                <div className="mt-4 flex flex-col gap-1">
                  <ProductViews
                    data={{products:products}}
                    bloom_filter={bloomData}
                    handleReloadData={handleReloadData}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div>
              <EmptyPage />
            </div>
          )}
        </>
      )}
      {pageIndex < 2 ? (
        <div>
          <div ref={loadingRef}></div>
        </div>
      ) : (
        <>
          {!handleShowMoreLoading && (
            <div className="flex items-center justify-center">
              {products?.length < totalElement ? (
                <div
                  onClick={handleLoadMore}
                  className="cursor-pointer text-14 font-medium text-blue-700"
                >
                  {capitalizedFirst(t("global.view-more"))}
                </div>
              ) : null}
            </div>
          )}
        </>
      )}
      {handleShowMoreLoading && (
        <div className="flex justify-center py-4">
          <i
            className="pi pi-spin pi-spinner text-blue-500"
            style={{ fontSize: "1.5rem" }}
          ></i>
        </div>
      )}
    </div>
  )
}
