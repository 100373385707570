import React, { useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { ImgCustomType } from "src/constants/constants/image-custom-type"
import MerchantHeaderCustom from "src/components/shopping-homepage-view/merchant-header-custom"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import CampaignProduct from "./campaign-product"
import { getCampaignSeller } from "src/services/home-connect-service"
import campaignBackIcon from "src/assets/images/campaign-back-icon.svg"
import { convertViToEn } from "src/utils/common"
import CountDownCampaignView from "./count-down-view"
export interface CampaignDetailType {
  name: string
  id: string
  endTime: string
  thumbnail: string
}
export default function CampaignDetailPage() {
  const navigate = useNavigate()
  const params = useParams()
  const {campaignId, shopId, name} = params
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [campaign, setCampaign] = useState<undefined | CampaignDetailType>(undefined)

  const getCampaign = () => {
    setLoading(true)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getCampaignSeller(shopId || "", 0, 100, "").then((res: any) => {
      if(res?.data?.data?.data?.length) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const result = res.data.data.data.find((i:any) => i.id === campaignId)
        if(result) {
          setLoading(false)
          setCampaign({
            id: result?.id || "",
            name: result?.name || "",
            endTime: result?.end_date || "",
            thumbnail: result?.thumbnail?.id,
          })
        } else {
          navigate("/not-found")
          return
        }
      } else {
        navigate("/not-found")
        return
      }
    }).catch(() => {
      setLoading(false)
      navigate("/not-found")
    })
  }

  const fetchData = () => {
    getCampaign()
  }

  const handleBack = () => {
    const shopCode = shopId && shopId.split("-")?.length ? shopId.split("-")[0] : ""
    navigate(
      `/shopping/merchant/${convertViToEn(name as string)}/${shopId}/${shopCode}`
    )
  }
  useEffect(() => {
    fetchData()
  }, [])

  const getCountDownTime = useMemo(() => {
    let result = 0
    if(campaign) {
      result = new Date(campaign.endTime).getTime()
    }
    return result
  }, [campaign])
  return (
    <div className="mx-auto w-full max-w-[1200px] px-2 py-3 xl:px-3">
      {loading ? (
        <div className="flex h-full min-h-[500px] w-full items-center justify-center gap-2">
          <div className="flex items-center justify-center text-16 font-semibold text-gray-700">
            {capitalizedFirst(t("global.loading"))}
          </div>
          <i
            className="pi pi-spin pi-spinner text-blue-light-500"
            style={{ fontSize: "2rem" }}
          ></i>
        </div>
      ) : (
        <div className="flex h-full w-full flex-col">
          {/* header */}
          <div className="w-full">
            <div className="rounded-3 border border-gray-100">
              {/* background and info */}
              <div className="relative">
                {/* background image */}
                <MerchantHeaderCustom
                  typeImg={ImgCustomType.image}
                  imgUrl={campaign?.thumbnail || ""}
                />
              </div>
            </div>
          </div>
          {/* header campaign */}
          <div className="flex px-[24px] py-[16px] bg-white justify-between ">
            <div className="flex flex-col">
              <div className="flex items-center gap-1">
                <div onClick={handleBack} className="p-1 border border-gray-300 rounded-3 hover:opacity-[50] cursor-pointer">
                  <img src={campaignBackIcon} alt="backIcon"/>
                </div>
                <span className="text-[20px] leading-[30px] font-semibold text-gray-900">{campaign?.name}</span>
              </div>
            </div>
            <div>
              <CountDownCampaignView target={getCountDownTime}/>
            </div>
          </div>
          {/* content */}
          <div className="w-full">
            <CampaignProduct />
          </div>
        </div>
      )}
    </div>
  )
}
