import React, { useEffect, useState } from "react"
import { CountDownCampaignType } from "./props"
import { useTranslation } from "react-i18next"

export default function CountDownCampaignView(props: CountDownCampaignType) {
  const { target } = props
  const [days, setDays] = useState<number>(0)
  const [hours, setHours] = useState<number>(0)
  const [minutes, setMinutes] = useState<number>(0)
  const [isTimeout, setIsTimeout] = useState<boolean>(false)
  const targetTime = new Date(target)
  const {t} = useTranslation()
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date()
      const different = targetTime.getTime() - now.getTime()

      const d = Math.floor(different/(1000*60*60*24))
      if(d > 1) setDays(d)
      const h = Math.floor(
        (different % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )

      setHours(h)
      const m = Math.floor((different % (1000 * 60 * 60)) / (1000 * 60))
      setMinutes(m)
      if (h <= 0 && m <= 0) {
        setIsTimeout(true)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [target])

  if (isTimeout) return null
  return (
    <div className="flex gap-1 flex-col">
      <span>{t("merchant-campaign.end-time")}</span>
      <div className="flex items-center">
        <div
          className={
            "gap-1 flex items-center justify-center rounded-1 bg-orange-dark-50 p-4px px-1 text-12 sm:text-16 font-semibold leading-6 text-orange-dark-500"
          }
        >
          {days <= 9 ? <div>{`0${days}`}</div> : <div>{days}</div>}
          <span>{t("merchant-campaign.day")}</span>
        </div>
        <div className={"text-12 sm:text-14 font-medium text-gray-600"}>:</div>
        <div
          className={
            "gap-1 flex items-center justify-center rounded-1 bg-orange-dark-50 p-4px px-1 text-12 sm:text-16 font-semibold leading-6 text-orange-dark-500"
          }
        >
          {hours <= 9 ? <div>{`0${hours}`}</div> : <div>{hours}</div>}
          <span>{t("merchant-campaign.hour")}</span>
        </div>
        <div className={"text-12 sm:text-14 font-medium text-gray-600"}>:</div>
        <div
          className={
            "gap-1 bg-orange-dark-50 flex items-center justify-center rounded-1 p-4px text-12 sm:text-16 font-semibold leading-6 text-orange-dark-500"
          }
        >
          {minutes <= 9 ? <div>{`0${minutes}`}</div> : <div>{minutes}</div>}
          <span>{t("merchant-campaign.minutes")}</span>
        </div>
      </div>
    </div>
  )
}
