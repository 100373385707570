import React, { useEffect, useRef, useState } from "react"
import "./App.scss"
import MainLayout from "./components/layout/main-layout"
import { Navigate, useLocation, useRoutes } from "react-router-dom"
import { Toast } from "primereact/toast"
import { ToastContext } from "./app/toast-context"
import { ROUTERS } from "./constants/router"
import ShoppingHomePage from "./pages/shopping/shopping-homepage"
import ProductDetail from "./pages/shopping/product-detail"
import ShoppingSearchProduct from "./pages/shopping/shopping-search-product"
import PurchaseHistory from "./pages/purchase-history"
import MerchantSearchPage from "./pages/merchant-search-page"
import MerchantPage from "./pages/merchant-page"
import OrderDetails from "./pages/order-details"
import MerchantCategory from "./pages/merchant-category"
import OrderCart from "./pages/order-cart"
import ReceiveToken from "./pages/receive-token"
import RequestOpen from "./pages/request-open"
import ShareToken from "./components/shareToken"
import { STATE_RENDER_APP } from "./constants/common"
import FlashSalePage from "./pages/shopping/shopping-flash-sale-page"
import MostViewPage from "./pages/most-view-page"
import LiveStreamPage from "./pages/livestream"
import LiveStreamRoom from "./pages/livestream-room"
import IframeApprovalRequest from "./components/iframe-approval-request"
import NotFoundPage from "./pages/not-found-page"
import RefundRequestPage from "./pages/refund-request"
import RefundDetailPage from "./pages/refund-detail"
import IframeApproveShop from "./pages/iframe-approve-shop"
import WelComePage from "./pages/welcome"
import PromotionLandingPage from "./pages/shopping/promotion-landing-page"
import { Helmet, HelmetProvider } from "react-helmet-async"
import CampaignDetailPage from "./pages/campaign-detail"

function App() {
  const routes = useRoutes([
    {
      path: ROUTERS.SHOPPING,
      element: <MainLayout />,
      children: [
        {
          element: <ShoppingHomePage />,
          path: ROUTERS.HOME,
          index: true,
        },
        {
          path: ROUTERS.PURCHASE_HISTORY,
          element: <PurchaseHistory />,
        },
        {
          path: ROUTERS.PRODUCT_DETAIL,
          element: <ProductDetail />,
        },
        {
          path: ROUTERS.SHOPPING_MERCHANT,
          element: <MerchantPage />,
        },
        {
          path: ROUTERS.SHOPPING_CAMPAIGN_DETAIL,
          element: <CampaignDetailPage />,
        },
        {
          path: ROUTERS.SHOPPING_SEARCH,
          element: <ShoppingSearchProduct />,
        },
        {
          path: ROUTERS.SHOPPING_FREE_SHIP,
          element: <ShoppingSearchProduct />,
        },
        {
          path: ROUTERS.SHOPPING_HOT_VOUCHER,
          element: <ShoppingSearchProduct />,
        },
        {
          path: ROUTERS.SHOPPING_TREND_DEALS,
          element: <ShoppingSearchProduct />,
        },
        {
          path: ROUTERS.ORDER_DETAILS,
          element: <OrderDetails />,
        },
        {
          path: ROUTERS.MERCHANT_SEARCH,
          element: <MerchantSearchPage />,
        },
        {
          path: ROUTERS.MERCHANT_CATEGORIES,
          element: <MerchantCategory />,
        },
        {
          path: ROUTERS.ORDER_CART,
          element: <OrderCart />,
        },
        {
          path: ROUTERS.FLASH_SALE,
          element: <FlashSalePage />,
        },
        {
          path: ROUTERS.MOST_PAGE,
          element: <MostViewPage />,
        },
        {
          path: ROUTERS.LIVESTREAM,
          element: <LiveStreamPage />,
        },
        {
          path: ROUTERS.LIVESTREAM_ROOM,
          element: <LiveStreamRoom />,
        },
        {
          path: ROUTERS.REFUND_DETAIL,
          element: <RefundDetailPage />,
        },
        {
          path: ROUTERS.REFUND_REQUEST,
          element: <RefundRequestPage />,
        },
        {
          path: "approval-request/:id",
          element: <IframeApprovalRequest />,
        },
        {
          path: "approval-request/shop/:id",
          element: <IframeApproveShop />,
        },
        {
          path: ROUTERS.PROMOTION,
          element: <PromotionLandingPage />,
        },
      ],
    },
    {
      path: `${ROUTERS.SHOPPING}/${ROUTERS.REQUEST_PAGE}`,
      element: <RequestOpen />,
    },
    {
      path: ROUTERS.SHARE_TOKEN,
      element: <ReceiveToken />,
    },
    {
      path: ROUTERS.APPROVAL_REQUEST,
      element: <IframeApprovalRequest />,
    },
    {
      path: ROUTERS.NOT_FOUND,
      element: <NotFoundPage />,
    },
    {
      path: ROUTERS.IFRAME_APPROVE_SHOP,
      element: <IframeApproveShop />,
    },
    {
      path: ROUTERS.WELCOME,
      element: <WelComePage />,
    },
    { path: "/", element: <Navigate to="/shopping" /> },
    { path: "*", element: <Navigate to="/not-found" /> },
  ])
  const [stateRenderView, setStateRenderView] = useState<string>(
    STATE_RENDER_APP.INIT
  )
  const location = useLocation()
  const detectLogin = () => {
    setStateRenderView(STATE_RENDER_APP.IFRAME)
  }

  useEffect(() => {
    if (
      !location.pathname.includes(ROUTERS.PURCHASE_HISTORY) &&
      !location.pathname.includes("/order-details") &&
      !location.pathname.includes("/refund-detail") &&
      !location.pathname.includes("/refund-request")
    ) {
      localStorage.removeItem("ORDER_TAB")
    }
  }, [location.pathname])
  useEffect(() => {
    function initView() {
      detectLogin()
    }
    initView()
  }, [])

  const toast = useRef<Toast>(null)
  if (stateRenderView === STATE_RENDER_APP.INIT) return null
  return (
    <HelmetProvider>
      <Helmet>
        <title>Soctrip Shop</title>
        <base href="/" />
        <meta
          name="description"
          content="Soctrip is the world's leading travel social network in online travel services in the fields of hotels, air tickets, tours, food, car rental, e-commerce platform, and travel experience sharing."
        />
        <meta
          property="og:description"
          content="Soctrip is the world's leading travel social network in online travel services in the fields of hotels, air tickets, tours, food, car rental, e-commerce platform, and travel experience sharing."
        />
        <meta
          name="twitter:description"
          content="Soctrip is the world's leading travel social network in online travel services in the fields of hotels, air tickets, tours, food, car rental, e-commerce platform, and travel experience sharing."
        />
        <meta
          property="og:image"
          content="%PUBLIC_URL%/soctrip-branding/soctrip-full.png"
        />
        <meta
          property="og:image:type"
          content="%PUBLIC_URL%/soctrip-branding/soctrip-full.png"
        />
        <meta
          property="og:image:secure_url"
          content="%PUBLIC_URL%/soctrip-branding/soctrip-full.png"
        />
        <meta
          name="twitter:image"
          content="%PUBLIC_URL%/soctrip-branding/soctrip-full.png"
        />
      </Helmet>
      <ToastContext.Provider value={toast}>
        {stateRenderView === STATE_RENDER_APP.APP ? routes : null}
        <ShareToken
          isLoading={true}
          stateRenderView={stateRenderView}
          setStateRenderView={(newValue: string) =>
            setStateRenderView(newValue)
          }
        />
        <Toast ref={toast} />
      </ToastContext.Provider>
    </HelmetProvider>
  )
}

export default App
