import http from "src/config/interceptors"

export const getUserInfo = (userId: string) => {
  return http.get(`/user/users/${userId}`)
}

export const createAccount = (email: string) => {
  const requestData = {
    email: email,
  }
  return http.post("/user/quick-signup", requestData)
}

export const getMyRegion = () => {
  return http.get("/regional/me")
}
export const getRegions = () => {
  return http.get(
    "/regional/regional/country?validCountry=true&isAllCountries=false"
  )
}

export const updateUserLanguage = (lng: string, currency: string) => {
  return http.put("/user/language-currency", {
    language: lng,
    currency: currency,
  })
}

export const getAcceptMegaLive = () => {
  return http.get("/shop/shop-management/mega-lives/find-by-user")
}

export const checkUserIsJoinShop = (shopId: string  ) => {
  return http.get(`/shop/home-connects/seller/${shopId}/customers/check-access`)
}

export const joinShop = (shopId: string, code: string) => {
  return http.post(`/shop/home-connects/customers/become-member/${shopId}?code=${code}`)
}


export const getMemberShop = (shopId: string) => {
  const userId = localStorage.getItem("userId") || ""
  return http.get(`/shop/home-connects/seller/${shopId}/customers/${userId}/members`)
}

