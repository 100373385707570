/* eslint-disable indent */
import { TabControllerApi } from "@soctrip/axios-order-service"
import { BreadCrumb } from "primereact/breadcrumb"
import { MenuItem } from "primereact/menuitem"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import ImgCustom from "src/components/img-custom"
import Loading from "src/components/loading"
import OrderDetailsFooter from "src/components/order-details-footer"
import OrderInformationDetails from "src/components/order-information"
import ShopOrder from "src/components/purchase-history/shop-order"
import { configHeader } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import { useToast } from "src/hooks/use-toast"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { convertCurrency, customValueByRate } from "src/utils/convert-currency"
import { ShopProperties } from "../purchase-history/props"
import "./index.scss"
import { OrderStatusTabDetails } from "./props"
// import shopIcon from "src/assets/images/shop-voucher.svg"
// import shipmentIcon from "src/assets/images/shipment-voucher-icon.svg"
import { OrderControllerApi } from "@soctrip/axios-order-service"
import i18n from "src/locales"
import { handlePromiseAll } from "src/utils/common"
import { Tooltip } from "primereact/tooltip"

export default function OrderDetails() {
  const navigate = useNavigate()
  const param = useParams()
  const showToast = useToast()
  const id = param?.id
  const elRef = useRef<HTMLDivElement>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [listIndex, setListIndex] = useState<number[]>([])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [orderData, setOrderData] = useState<any>()
  const [orderStatusTabDetails, setOrderStatusTabDetails] = useState<OrderStatusTabDetails[]>([])

  const [estimatedArrivalTime, setEstimatedArrivalTime] = useState()

  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTranslate = (text: any) => {
    return t(text)
  }

  const items: MenuItem[] = [
    {
      label: capitalizedFirst(handleTranslate("purchase-history.shopping")),
      command: () => {
        navigate("/")
      },
    },
    {
      label: capitalizedFirst(handleTranslate("purchase-history.my-order")),
      className: "font-semibold",
      command: () => {
        navigate("/shopping/orders")
      },
    },
    {
      label: capitalizedFirst(handleTranslate("purchase-history.button.order-details")),
      className: "font-semibold",
      command: () => {
        navigate(`/shopping/order-details/${id}`)
      },
    },
  ]
  const home: MenuItem = {
    icon: "pi pi-home",
    command: () => {
      navigate("/")
    },
  }

  useEffect(() => {
    handleGetDataOrderDetails()
  }, [i18n.language])

  const getOrderDetail = async () => {
    if (id) {
      await new OrderControllerApi(configHeader(SERVICE.ORDER))
        .ordersIdGet(id, i18n.language)
        .then((response) => {
          const data = response?.data?.data
          if (data) {            
            setOrderData(data)
          }
        })
        .then((error) => error)
    }
  }

  const getEstimatedArrivalTime = async () => {
    if(id) {
      await new OrderControllerApi(configHeader(SERVICE.ORDER))
      .ordersIdEstimatedArrivalTimeGet(id as string, i18n.language)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
          setEstimatedArrivalTime(response?.data?.data)
        })
      .catch((error) => error)
    }
  }

  const handleGetDataOrderDetails = async () => {
    if(!id) return
    const apis = [getTabDetails(), getOrderDetail(), getEstimatedArrivalTime()]
    setLoading(true)
    handlePromiseAll(apis).then(() => {
      setLoading(false)
    })
  }

  const handleReload = () => {
    handleGetDataOrderDetails()
  }

  const formatDate = (date: string | undefined) => {
    if (date) {
      const newTime = new Date(date)?.toLocaleString("en-GB")
      return newTime
    }
  }

  const copy = (text?: string) => {
    if (text) {
      navigator.clipboard.writeText(text)
    }
  }

  const getTabDetails = async () => {
    await new TabControllerApi(configHeader(SERVICE.ORDER))
      .tabsOrdersIdGet(id as string)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        setOrderStatusTabDetails(response?.data?.data)
      })
      .catch((error) => error)
  }
  const handleCopy = (code: string | undefined) => {
    if (code) {
      copy(code)
      showToast({ detail: t("purchase-history.copy-success"), severity: "success" })
    } else {
      showToast({ detail: t("purchase-history.copy-failed"), severity: "error" })
    }
  }

  const isActive = (index: number) => listIndex.includes(index)

  const handleGetStatus = () => {
    const tabData = orderData?.status?.status_property?.tab
    const indexStatus = orderStatusTabDetails.findIndex((status) => status?.code === tabData)
    const array = Array.from(Array(indexStatus + 1).keys())
    setListIndex(array)
  }

  useEffect(() => {
    if (orderData) {
      handleGetStatus()
    }
  }, [orderData])

  const getStyleVoucher = (voucherType: string) => {
    let result = "bg-orange-dark-50 text-orange-700"
    if(voucherType === "FREE_SHIP") result = "bg-emerald-50 text-emerald-600"    
    return result
  }
  
  console.log(orderData, "orderData")
  return (
    <div className="mx-auto w-full max-w-[1200px] px-2 xl:px-3">
      <div className="mb-4 flex w-full flex-col">
        {/* breadcrumb */}
        <div className="item-center flex w-full">
          <BreadCrumb
            model={items}
            home={home}
            className="flex h-[52px] w-full items-center border-none bg-gray-50 text-16"
          />
        </div>
        {/* content */}
        {loading ? (
          <div className="h-[700px] w-full">
            <Loading />
          </div>
        ) : (
          <div className="flex h-full w-full flex-col gap-3 overflow-auto">
            {/* order information */}
            <div className="w-full">
              <OrderInformationDetails
                code={orderData?.code}
                status_name={orderData?.status?.name}
                user_full_name={orderData?.address?.user_full_name}
                note={orderData?.note}
                created_at={orderData?.payment?.created_at}
                user_phone_number={orderData?.address?.user_phone_number}
                address={orderData?.address}
                color={orderData?.status?.status_property?.color}
                font_color={orderData?.status?.status_property?.font_color}
                shipment_code={orderData?.shipment_code}
                shipment_track_url={orderData?.shipment_track_url}
                estimatedArrivalTime={estimatedArrivalTime}
                shops={orderData?.shops}
              />
            </div>
            {/* order status */}
            <div className="flex w-full flex-col rounded-3 bg-white">
              <div className="flex h-[52px] w-full items-center border-b border-gray-100 py-3 px-2 text-18 font-semibold text-gray-800 md:px-3">
                {capitalizedFirst(handleTranslate("purchase-history.order-status"))}
              </div>
              <div className=" flex w-full flex-row items-center py-3 px-2 md:px-3">
                {orderStatusTabDetails?.map((status, index) => (                                   
                  <div
                    key={index}
                    className="flex h-[32px] w-full flex-1 justify-center md:h-[44px] cursor-pointer"
                  >
                    <Tooltip target=".target-name" />
                    <div
                      data-pr-tooltip={capitalizedFirst(t(`purchase-history.order-status-tabs.${status.name}`))}
                      data-pr-position="top"
                      data-pr-at="right-1 top"
                      className={`target-name flex w-full items-center md:justify-center ${
                        isActive(index) ? "bg-blue-500" : "bg-gray-100"
                      } relative ${
                        index == orderStatusTabDetails.length - 1 && "rounded-r-[100px]"
                      } ${index == 0 && "rounded-l-[100px]"}`}
                      style={{
                        backgroundColor: `${isActive(index) ? status?.color : ""}`,
                      }}
                    >
                      <span
                        className={`z-[11] w-[70%] pl-1 text-10 font-semibold line-clamp-1 md:pl-[0px] md:text-16 ${
                          isActive(index) ? "text-white" : "text-gray-500"
                        }`}
                      >
                        {capitalizedFirst(t(`purchase-history.order-status-tabs.${status.name}`))}
                      </span>
                      {index !== orderStatusTabDetails.length - 1 && (
                        <>
                          <div
                            className={`${
                              isActive(index) ? "bg-blue-500" : ""
                            } absolute right-[3px] top-[4px] z-[10] h-[24px] w-[24px] rotate-45 border-2 border-l-0 border-b-0 border-r-white border-t-white md:right-[0] md:top-[6px] md:h-[32px] md:w-[32px]`}
                            style={{
                              backgroundColor: `${isActive(index) ? status?.color : ""}`,
                            }}
                          ></div>
                          <div
                            className={"wd:w-[20px] absolute right-0 h-[32px] w-[16px] md:h-[44px]"}
                            style={{
                              backgroundColor: `${
                                isActive(index + 1)
                                  ? orderStatusTabDetails?.[index + 1]?.color
                                  : "#f2f4f7"
                              }`,
                            }}
                          ></div>
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* order status details */}
            <div className="flex h-full w-full flex-col rounded-3 bg-white">
              <div className="flex h-[60px] w-full items-center border-b border-gray-100">
                <p className="flex items-center py-3 px-2 text-18 font-semibold text-gray-800 md:px-3">
                  {capitalizedFirst(handleTranslate("purchase-history.order-status-details"))}
                </p>
              </div>
              {/* order status details content */}
              {orderData?.status_details?.length ? (
                <div className="h-full w-full px-2 pt-1 pb-3 md:px-3">
                  {orderData?.status_details?.map(
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (item: any, index: number) => (
                      <div key={item?.id}>
                        {item?.is_public ? (
                          <div
                            ref={elRef}
                            className={`flex h-full w-full gap-2 ${index !== 0 ? "mt-3" : "mt-2"}`}
                          >
                            <div
                              className="flex flex-col gap-1 pt-10px"
                              style={{
                                height: `${elRef?.current?.clientHeight}`,
                              }}
                            >
                              <span
                                className={`pi pi-circle-fill text-10 text-gray-500 ${
                                  index + 1 === orderData?.status_details?.length
                                    ? "text-orange-dark-400"
                                    : ""
                                }`}
                              ></span>
                              {index + 1 !== orderData?.status_details?.length && (
                                <div className="ml-4px mt-4px h-full w-full border-l-2 border-gray-200"></div>
                              )}
                            </div>
                            <div className="flex h-fit w-full flex-col gap-6px">
                              <div className="flex items-center gap-1">
                                <div
                                  className={
                                    "rounded-5 bg-gray-200 px-2 py-4px text-14 font-medium text-gray-700 "
                                  }
                                  style={{
                                    backgroundColor: item?.status_property?.color,
                                    color: item?.status_property?.font_color,
                                  }}
                                >
                                  {item?.name}
                                </div>
                              </div>
                              <div className="text-14 font-medium text-gray-700">{item?.note}</div>
                              <div className="flex gap-1">
                                {item?.attachments?.map(
                                  (attachment: { id: string }, index: number) => {
                                    return (
                                      <div key={index}>
                                        <ImgCustom
                                          url={attachment?.id}
                                          alt="image"
                                          className="h-[70px] w-[72px] rounded-2 object-cover object-center"
                                        />
                                      </div>
                                    )
                                  }
                                )}
                              </div>
                              <div className="text-14 font-normal leading-5 text-gray-500">
                                {item?.message}
                              </div>
                              <div className="text-12 font-medium text-gray-500">
                                {formatDate(item?.created_at)}
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    )
                  )}
                </div>
              ) : (
                <div className="p-3 text-16 font-semibold text-gray-500">
                  {capitalizedFirst(handleTranslate("global.empty-data"))}
                </div>
              )}
            </div>
            {/* product list */}
            <div className="flex h-full w-full flex-col rounded-3 border-b border-gray-100 bg-white pb-3">
              <div className="border-100 flex h-[60px] w-full items-center gap-1 border-b py-3 px-2 md:px-3">
                <p className="flex items-center text-18 font-semibold text-gray-800">
                  {capitalizedFirst(handleTranslate("purchase-history.order-list"))}
                </p>
                <div className="flex items-center gap-4px rounded-5 bg-gray-200 px-1 py-2px">
                  <span className="pi pi-circle-fill text-[6px] text-gray-500"></span>
                  <p className="text-16 font-medium text-gray-700">{orderData?.total_item}</p>
                  <p className="text-16 font-medium text-gray-700">
                    {capitalizedFirst(
                      handleTranslate(
                        orderData?.total_item > 1
                          ? "purchase-history.order-items"
                          : "purchase-history.item"
                      )
                    )}
                  </p>
                </div>
              </div>
              {/* content product list */}
              <div className="h-full w-full border-b border-gray-100 pt-3 pb-1">
                {orderData?.shops?.map((shop: ShopProperties, index: number) => (
                  <div key={index} className="relative h-full w-full">
                    <ShopOrder
                      shopId={shop?.shop_id}
                      shopName={shop?.name}
                      avatar={shop?.avatar?.id}
                      products={shop?.products}
                    />
                    {/* order id */}
                    {shop?.code ? (
                        <div className="absolute top-3 right-4 flex items-center gap-1">
                          <div className="flex max-w-[180px] items-center gap-1">
                            <p className="truncate text-16 font-normal text-gray-900">
                              {shop?.code}
                            </p>
                            <span
                              onClick={() => handleCopy(shop?.code)}
                              className="pi pi-clone cursor-pointer text-20 text-blue-700"
                            ></span>
                          </div>
                        </div>
                      ) : null}
                  </div>
                ))}
              </div>
              {/* payment information */}
              <div className="flex h-full w-full flex-col gap-1 border-b border-gray-100 pb-3">
                <div className="h-[60px] w-full border-b border-gray-100">
                  <p className="flex w-full items-center py-3 px-2 text-18 font-semibold text-gray-800 md:px-3">
                    {capitalizedFirst(handleTranslate("purchase-history.payment-information"))}
                  </p>
                </div>
                <div className="flex h-full w-full flex-col gap-1 pt-1">
                  {/* Total */}
                  <div className="flex items-center justify-between px-2 md:px-3">
                    <p className="text-16 font-semibold text-gray-700">
                      {capitalizedFirst(handleTranslate("purchase-history.order-total"))}
                    </p>
                    <p className="text-18 font-semibold text-gray-700">
                      {
                        orderData?.payment?.origin_rate ? customValueByRate(orderData?.payment?.origin_rate, orderData?.payment?.order_total) : convertCurrency(orderData?.payment?.order_total)
                      }
                    </p>
                  </div>

                  <div className="flex w-full flex-col gap-1">
                    {/* Payment method */}
                    <div className="flex items-center justify-between px-2 md:px-3">
                      <p className="text-14 font-medium text-gray-500">
                        {capitalizedFirst(handleTranslate("purchase-history.payment-method"))}
                      </p>
                      <p className="text-14 font-normal text-gray-700">
                        {orderData?.payment?.payment_method?.name}
                      </p>
                    </div>

                    {/* voucher */}
                    <div className="flex w-full items-center justify-between px-2 md:px-3">
                      <div className="min-w-[150px] truncate text-14 font-medium text-gray-500">
                        {capitalizedFirst(handleTranslate("checkoutPage.vouchers"))}
                      </div>
                      <div className="flex w-full items-center justify-end gap-1">
                        {orderData?.payment?.vouchers?.map(
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          (voucher: any, index: number) => (
                            <div key={index} className="flex">
                              <div
                                className={`${getStyleVoucher(voucher?.type)} flex  items-center gap-4px rounded-5 px-10px py-4px text-14 font-medium`}
                              >
                                {/* <span>
                                  {voucher?.type == "FREE_SHIP" ? (
                                    <img src={shipmentIcon} alt="icon" className="w-2 h-2"/>
                                  ) : (
                                    <img src={shopIcon} alt="icon" />
                                  )}
                                </span> */}
                                <span>{voucher?.name}</span>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>

                    {/* tax */}
                    <div className="flex w-full items-center justify-between px-2 md:px-3">
                      <div className="text-14 font-medium text-gray-500">
                        {capitalizedFirst(handleTranslate("shoppingCartPage.tax"))}
                      </div>
                      <p className="text-14 font-normal text-gray-700">
                        {
                          orderData?.payment?.origin_rate ? customValueByRate(orderData?.payment?.origin_rate, orderData?.payment?.tax) : convertCurrency(orderData?.payment?.tax)
                        }
                      </p>
                    </div>

                    {/* discounts */}
                    <div className="flex w-full items-center justify-between px-2 md:px-3">
                      <div className="text-14 font-medium text-gray-500">
                        {capitalizedFirst(handleTranslate("shoppingCartPage.discounts"))}
                      </div>
                      <p className="text-14 font-normal text-gray-700">
                        {orderData?.payment?.discounts ? "-" : null}
                        {
                          orderData?.payment?.origin_rate ? customValueByRate(orderData?.payment?.origin_rate, orderData?.payment?.discounts) : convertCurrency(orderData?.payment?.discounts)
                        }
                      </p>
                    </div>

                    {/* delivery */}
                    <div className="flex w-full items-center justify-between px-2 md:px-3">
                      <div className="text-14 font-medium text-gray-500">
                        {capitalizedFirst(handleTranslate("shoppingCartPage.delivery"))}
                      </div>
                      <p className="text-14 font-normal text-gray-700">
                        {
                          orderData?.payment?.origin_rate ? customValueByRate(orderData?.payment?.origin_rate, orderData?.payment?.shipment_fee || 0) : convertCurrency(orderData?.payment?.shipment_fee || 0)
                        }
                      </p>
                    </div>

                    {/* Delivery discount */}
                    <div className="flex w-full items-center justify-between px-2 md:px-3">
                      <div className="text-14 font-medium text-gray-500">
                        {capitalizedFirst(handleTranslate("shoppingCartPage.delivery-discount"))}
                      </div>
                      <p className="text-14 font-normal text-gray-700">
                        {orderData?.payment?.shipment_discount ? "-" : null}
                        {
                          orderData?.payment?.origin_rate ? customValueByRate(orderData?.payment?.origin_rate, orderData?.payment?.shipment_discount || 0) : convertCurrency(orderData?.payment?.shipment_discount || 0)
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* total payment */}
              <div className="flex h-full w-full items-center justify-between py-3 px-2 md:px-3">
                <p className="text-16 font-semibold text-gray-700">
                  {capitalizedFirst(handleTranslate("purchase-history.total-payment"))}
                </p>
                <p className="text-18 font-bold text-orange-dark-500">
                  {
                    orderData?.payment?.origin_rate ? customValueByRate(orderData?.payment?.origin_rate, orderData?.payment?.payment_total || 0) : convertCurrency(orderData?.payment?.payment_total || 0)
                  }
                </p>
              </div>
              {/* footer */}
              <OrderDetailsFooter
                handleCancelOrder={handleReload}
                id={orderData?.id}
                code={orderData?.status?.code}
                can_cancel={orderData?.status?.status_property?.can_cancel}
                can_receive={orderData?.status?.status_property?.can_receive}
                can_refund={orderData?.status?.status_property?.can_refund}
                can_repay={orderData?.status?.status_property?.can_repay}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
